@import "skin";

//----------- Race Report Component Start --------------
.pmu-race-report-wrapper {
  margin-top: 10px;
}

.pmu-r-r-h {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 30px;
}

.pmu-r-r-h-c {
  display: table-cell;

  [dir=ltr] & {
    padding-right: 2px;
  }
  [dir=rtl] & {
    padding-left: 2px;
  }
  &:last-child {
    padding: 0;
  }
}

.pmu-r-r-h-c-t {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: c(skin-7);
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  line-height: 30px;
  transition-property: color, background;
  transition-duration: $transition-length;
  cursor: pointer;

  &:after {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $brand-color-1-bg;
    transition: width $transition-length;
  }

  &:hover,
  &.active {
    color: c(text-1);
    background: c(skin-9);
  }

  &.active {
    &:after {
      width: 100%;
    }
  }
}

.pmu-r-r-b {
  margin-top: 2px;
  background: c(skin-8);
  transform: translate3d(0, 0, 0);
}

.pmu-r-r-b-reports-wrapper {

}

.pmu-r-r-b-r-i {
  border-top: 1px solid c(text-1, 0.2);

  &:first-child {
    border-top: none;
  }
}

.pmu-r-r-b-r-i-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 47px;
  padding: 0 10px;
}

.pmu-r-r-b-r-i-t-c {
  display: table-cell;
  vertical-align: middle;

  &:nth-child(1) {
    width: 82px;
    position: relative;
    text-align: center;

    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 26px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: rgba($color-white, 0.2);

      [dir=ltr] & {
        right: 0;
      }
      [dir=rtl] & {
        left: 0;
      }
    }
  }
  &:nth-child(2) {

  }
  &:nth-child(3) {
    width: 18px;
  }
}

.pmu-r-r-b-r-i-t-c-icon {
  display: inline-block;
}

.pmu-r-r-b-r-i-t-c-t {
  display: block;
  padding: 0 12px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-r-r-b-r-i-t-c-a-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: c(text-4);
  transition-property: color, transform;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover,
  &.active {
    color: c(text-1);
  }

  &.active {
    transform: rotateX(180deg);
  }
}

.pmu-r-r-b-r-i-b {
  padding: 2px 0 16px 0;
}

.pmu-r-r-b-r-i-b-t {
  padding: 0 15px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
}

.pmu-r-r-b-r-i-b-c {
  padding-top: 8px;
}

.pmu-r-r-b-r-i-b-t-d {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 22px;
  padding: 0 10px;

  &:nth-child(even) {
    background: c(skin-6, 0.5);
  }

  &.head {
    margin-bottom: 8px;

    > .pmu-r-r-b-r-i-b-t-d-c {
      font-size: 12px;
      text-transform: capitalize;
    }
  }
}

.pmu-r-r-b-r-i-b-t-d-c {
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);

  &:nth-child(1),
  &:nth-child(2){
    [dir=ltr] & {
      text-align: left;
    }
    [dir=rtl] & {
      text-align: right;
    }
  }
  &:nth-child(3) {
    [dir=ltr] & {
      text-align: right;
    }
    [dir=rtl] & {
      text-align: left;
    }
  }
}

.pmu-r-r-b-arrivals-wrapper {
}

.pmu-r-r-b-a-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  min-height: 246px;
}

.pmu-r-r-b-a-t-c {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  position: relative;

  &.list {
    [dir=ltr] & {
      padding: 0 20px 0 10px;
    }
    [dir=rtl] & {
      padding: 0 10px 0 20px;
    }
  }

  &.image {
    padding: 0 20px;
  }

  &:first-child {
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 90%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: c(text-1, 0.2);

      [dir=ltr] & {
        right: 0;
      }
      [dir=rtl] & {
        left: 0;
      }
    }
  }
}

.pmu-r-r-b-a-t-c-l-i {
  height: 46px;
  padding: 10px 0;
  box-shadow: 0 -1px 0 0 c(text-1, 0.2) inset;

  &:last-child {
    box-shadow: none;
  }
}

.pmu-r-r-b-a-t-c-l-i-c {
  height: 100%;
  position: relative;

  [dir=ltr] & {
    padding-left: 36px;
  }
  [dir=rtl] & {
    padding-right: 36px;
  }
}

.pmu-r-r-b-a-t-c-l-i-c-n {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;
  font-weight: normal;
  font-size: 14px;
  color: c(text-4);
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 1px;
    height: 26px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: c(skin-1, 0.2);

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }

  &.not-calculated {
    color: c(text-10);
  }
}

.pmu-r-r-b-a-t-c-l-i-c-t {
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir=ltr] & {
    padding-left: 10px;
  }
  [dir=rtl] & {
    padding-right: 10px;
  }

  &.t-1 {
    font-size: 12px;
    color: c(text-2);
    line-height: 14px;
  }
  &.t-2 {
    font-size: 10px;
    color: c(text-4);
    line-height: 12px;
  }
}

.pmu-r-r-b-a-t-c-scr-sh {

}

.pmu-r-r-b-a-t-c-scr-sh-t {
  display: block;
  height: 46px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-4);
  text-transform: capitalize;
  line-height: 46px;
}

.pmu-r-r-b-a-t-c-scr-sh-i-w {
  width: 100%;
  max-height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.pmu-r-r-b-a-t-c-scr-sh-i {
  display: block;
  width: 100%;
}

.pmu-r-r-b-participants-wrapper {

}

.pmu-r-p-b-states {
  display: table;
  //padding-top: 4px;

  [dir=ltr] & {
    float: right;
  }

  [dir=rtl] & {
    float: left;
  }
}

.pmu-r-p-b-state {
  display: inline-block;
  min-width: 110px;
  height: 26px;
  border: 1px solid $brand-color-1-bg;
  outline: none;
  background: transparent;
  border-radius: 2px 0 0 2px ;
  padding: 0 8px;
  font-weight: normal;
  font-size: 12px;
  color: $brand-color-1-txt;
  text-align: center;
  line-height: 20px;
  transition-duration: $transition-length;
  transition-property: color, background;
  cursor: pointer;

  //[dir=ltr] & {
  //  margin-right: 8px;
  //}
  //
  //[dir=rtl] & {
  //  margin-left: 8px;
  //}

  &:last-child {
    margin: 0;
    border-radius: 0 2px 2px 0 ;
  }

  &:hover,
  &.active {
    background: $brand-color-1-bg;
  }
}

//----------- Race Report Component End --------------

@media screen and (max-width: 979px) {
  .pmu-race-report-wrapper {
    margin-top: 5px;
  }
}