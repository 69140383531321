@import "skin";

// ---------- QuickBet Start ----------
.pmu-quick-bet-wrapper {
  width: 100%;
}

.pmu-quick-b-list-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  // background: c(skin-1);
}

.pmu-q-b-column {
  display: table-cell;
  vertical-align: top;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: relative;

  [dir=ltr] & {
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }

  [dir=rtl] & {
    &:first-child {
      border-right: 0;
    }
    &:last-child {
      border-left: 0;
    }
  }
}

.pmu-q-b-c-content {
  height: 100%;
  position: relative;
  padding-top: 50px;
  padding-bottom: 48px;
  background: c(skin-9);

  &.gradientColor{
    background:radial-gradient(circle at 0 100%, #564038, c(skin-9) 38%);  // need to change color , add class if have quinte bet
  }
}

.pmu-q-b-c {
  &.head {
    width: 100%;
    height: 50px;
    padding: 10px;
    position: absolute;
    top: 0;
    background: c(skin-7);

    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }

    > .pmu-q-b-c-t {
      height: 100%;

      > .pmu-q-b-c-t-c {

        &:nth-child(3) {
          width: 20%;
        }

        &:last-child {
          width: 15%;
        }
      }
    }


  }
  &.body {
    margin-top: 2px;
    padding: 0 10px;

    > .pmu-q-b-c-t {
      height: 46px;
      padding: 8px 0;
      border-bottom: 1px solid rgba(c(text-7), 0.5);

      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &.foot {
    width: 100%;
    height: 48px;
    margin-top: 2px;
    padding: 10px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    &:hover{
      background: linear-gradient(to top, c(skin-8) 60%, c(skin-9));
      cursor: pointer;
    }
  }
}

.pmu-q-b-c-t {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.pmu-q-b-c-t-c {
  display: table-cell;

  [dir=ltr] & {
    padding-right: 8px;
  }
  [dir=rtl] & {
    padding-left: 8px;
  }

  &:nth-child(1) {
    width: 64px;
  }
  &:nth-child(2) {
    width: 50%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 20%;
  }

  &.ext-1 {
    vertical-align: top;
  }

  &.ext-2 {
    vertical-align: middle;
  }

  &:last-child {
    padding: 0;
  }
}

.pmu-q-b-c-t-c-h {
  display: block;
  height: 18px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.ext-1{
    position: relative;

  }
}

.pmu-q-b-c-t-c-h-t-z{
  display: inline-block;
  vertical-align: top;
  height: 18px;
  padding: 0 4px;
  position: absolute;
  top:0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  font-size: 12px;
  line-height: 18px;
  background: c(skin-6);
  color: c(text-3);
  -webkit-user-select: none;

  [dir=ltr] & {
   right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-q-b-c-t-c-d {
  display: block;
  padding-top: 4px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.default {
    color: c(text-4);
  }

  &.ext-1 {
    color: $pmu-quick-bet-time-color-1;
  }

  &.ext-2 {
    color: $pmu-quick-bet-time-color-2;
  }
}

.pmu-q-b-c-f-btn {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 10px;
  color: c(text-4);
  line-height: 14px;
  border-bottom: 1px solid c(text-4);
  transition-property: color, border;
  transition-duration: $transition-length;
  cursor: pointer;

  &:first-letter {
    text-transform: capitalize;
  }

  &:hover {
    border-color: c(text-1);
    color: c(text-1);
  }
}

.pmu-q-b-c-t-c-f-c {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  [dir=ltr] & {
    padding-right: 28px;
  }
  [dir=rtl] & {
    padding-left: 28px;
  }
}

.pmu-q-b-c-t-c-f-c-t {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  color: c(text-4);
  line-height: 24px;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 1px;
    height: 26px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: rgba(c(skin-11), 0.5);;

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }
}

.pmu-q-b-c-t-c-f-c-i-w{
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    right: 0;
    text-align: right;
  }
  [dir=rtl] & {
    left: 0;
    text-align: left;
  }
}

.pmu-q-b-c-t-c-f-c-img {
  display: inline-block;
  vertical-align: top;
  height: 100%;

  &[src=""]{
    display: none;
  }
}

.pmu-q-b-c-t-c-txt {
  display: block;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.t-1 {
    font-size: 12px;
    line-height: 14px;
    color: c(text-2);
  }
  &.t-2 {
    font-size: 10px;
    line-height: 12px;
    color: c(text-4);
  }
}

.pmu-q-b-c-t-c-t-r {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-4);
}

.pmu-q-b-c-t-c-btn{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 120px;
  height: 26px;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $brand-color-1-bg;
  transition-property: background, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover, &.active {
    background: $brand-color-1-bg;

    > .pmu-q-b-c-t-c-btn-i{
      color: $brand-color-1-txt;
    }
  }
}

.pmu-q-b-c-t-c-btn-i{
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 100%;
  font-weight: normal;
  font-size: 12px;
  color: $brand-color-1-txt;
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir=ltr] & {
    padding-left: 14px;
  }
  [dir=rtl] & {
    padding-right: 14px;
  }
}

.pmu-price-q-b-icon{
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  position: absolute;
  top:0;
  bottom: 0;
  font-size: 14px;
  margin: auto 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}
///----------------------------------------

.pmu-q-single-bet-v {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 51px;
}

.pmu-race-event-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  background: rgba(c(skin-4), 0.8);
  &:nth-last-child(2) {
    > li {
      border: none;
    }
  }
  > li {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 4px;
    border-bottom: 1px solid rgba($color-white, 0.1);
    &:first-child {
      width: 52px;

      [dir=ltr] & {
        padding-left: 0;
      }
      [dir=ltr] & {
        padding-right: 0;
      }
    }
    &:last-child {
      width: 90px;
      text-align: center;

      [dir=ltr] & {
        padding-right: 0;
      }
      [dir=rtl] & {
        padding-left: 0;
      }
    }
    &.coef-q-b {
      width: 20%;
    }
    > p {
      width: 100%;
      font-size: 12px;
      line-height: 14px;
      margin: 0 0 3px;
      color: c(text-2);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > span {
      display: block;
      width: 100%;
      font-size: 10px;
      line-height: 12px;
      color: c(text-3);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.time-st-green {
        color: $pmu-quick-bet-time-color-1;
      }

      &.time-st-red {
        color: $pmu-quick-bet-time-color-2;
      }
    }
  }
  &.caption {
    position: absolute;
    top: 0;
    left: 0;
    background: c(skin-7);
    > li {
      vertical-align: top;
      border: none;
    }
  }
}

.pmu-price-q-b {
  width: 100%;
  max-width: 120px;
  height: 26px;
  padding: 0 10px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $brand-color-1-bg;
  text-align: center;
  transition-property: background, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover, &.active {
    color: $brand-color-1-txt;
    background: $brand-color-1-bg;
  }
}

.pmu-price-q-b-i {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  color: $brand-color-1-txt;
  line-height: 26px;
  position: relative;
}

.pmu-price-q-b-icon-2 {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  font-size: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    left: -12px;
  }
  [dir=rtl] & {
    right: -12px;
  }
}

.pmu-more-l-w {
  width: 100%;
  text-align: right;
  padding: 0 10px 20px;
  background: rgba(c(skin-6), 0.8);
  > h5 {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: c(text-10);
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px solid c(text-10);
    padding: 10px 0 2px;
    cursor: pointer;
    transition-property: border-color, color;
    transition-duration: $transition-length;
    > a {
      color: inherit;
    }
    &:hover {
      border-color: c(text-1);
      color: c(text-1);
    }
  }
}

.pmu-n-m-r-t {
  display: table;
  width: 100%;
  height: 100%;
}

.pmu-n-m-r-t-c {
  display: table-cell;
  vertical-align: middle;
  font-weight: normal;
  font-size: 22px;
  color: c(text-7);
  text-transform: capitalize;
  text-align: center;
  background: rgba(c(skin-4), 0.8);
}

.pmu-number-polo {
  > p {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    line-height: 26px;

    [dir=ltr] & {
      margin: 0 4px 0 0;
      border-right: 1px solid c(skin-5);
    }
    [dir=rtl] & {
      margin: 0 0 0 4px;
      border-left: 1px solid c(skin-5);
    }
  }
}

.pmu-j-icon {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 26px;
  //background: c(skin-5);
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}

.pmu-j-icon-i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

// ---------- QuickBet End ----------

@media screen and (max-width: 979px) {

  .pmu-quick-b-list-t {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .pmu-q-b-column {
    display: inline-table;
    width: 95%;
  }

}