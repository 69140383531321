
@import "settings";
$hero-color: #a61f67      !default;
$brand-color-1: #1a7051   !default;
$brand-color-2: #ac8336   !default;
$coef-color: #b6862e      !default;
$favorite-color: #c3953c  !default;

$skin-color: #282c38      !default;
$text-color: $skin-color  !default;

$header-bg: $skin-color   !default;

$body-background: $skin-color;


$colors: (
        hero: $hero-color,
        brand: $brand-color-1,
        accent: $brand-color-2,
        odd: $coef-color,
        favorite: $favorite-color,

        skin: $skin-color,
        text: $text-color,

        header: $header-bg,

        skin-1: lighten($skin-color, 100),                                              // #ffffff
        skin-2: lighten( desaturate( adjust-hue( $skin-color, 15deg ), 8 ), 77 ),       // #f3f3f5
        skin-3: lighten( desaturate( adjust-hue( $skin-color, -1deg ), 7 ), 59 ),       // #c2c5cd
        skin-4: lighten( desaturate( adjust-hue( $skin-color, 2deg ), 2 ), 50 ),        // #a5aabc
        skin-5: lighten( desaturate( adjust-hue( $skin-color, 1deg ), 6 ), 41 ),        // #8d92a3
        skin-6: lighten( desaturate( adjust-hue( $skin-color, -1deg ), 8 ), 34 ),       // #7b8191
        skin-7: lighten( desaturate( adjust-hue( $skin-color, -3deg ), 4 ), 17 ),       // #505767
        skin-8: lighten( desaturate( adjust-hue( $skin-color, -3deg ), 3 ), 10 ),       // #3f4553
        skin-9: lighten( desaturate( adjust-hue( $skin-color, -6deg ), 3 ), 4 ),        // #323842
        skin-10: lighten( desaturate( $skin-color, 2 ), 2 ),                            // #2e323e
        skin-11: $skin-color,                                                           // #282c38
        skin-12: darken( saturate( adjust-hue( $skin-color, 1deg ), 3 ), 3 ),           // #212532
        skin-13: darken( saturate( adjust-hue( $skin-color, 1deg ), 9 ), 6 ),           // #181C29
        skin-14: darken( saturate( adjust-hue( $skin-color, 1deg ), 12 ), 9 ),          // #121623
        skin-15: darken( saturate( adjust-hue( $skin-color, -15deg ), 13 ), 11 ),       // #0e141a
        skin-16: darken($skin-color, 100),                                              // #000000

        text-1: set-color($skin-color),                                                                              // #ffffff
        text-2: set-light-skin(lighten( desaturate( adjust-hue( $text-color, 15deg ), 8 ), 77 ), $skin-color),       // #f3f3f5
        text-3: set-light-skin(lighten( desaturate( adjust-hue( $text-color, -1deg ), 7 ), 59 ), $skin-color),       // #c2c5cd
        text-4: set-light-skin(lighten( desaturate( adjust-hue( $text-color, 2deg ), 2 ), 50 ), $skin-color),        // #a5aabc
        text-5: set-light-skin(lighten( desaturate( adjust-hue( $text-color, 1deg ), 6 ), 41 ), $skin-color),        // #8d92a3
        text-6: set-light-skin(lighten( desaturate( adjust-hue( $text-color, -1deg ), 8 ), 34 ), $skin-color),       // #7b8191
        text-7: set-light-skin(lighten( desaturate( adjust-hue( $text-color, -3deg ), 4 ), 17 ), $skin-color),       // #505767
        text-8: set-light-skin(lighten( desaturate( adjust-hue( $text-color, -3deg ), 3 ), 10 ), $skin-color),       // #3f4553
        text-9: set-light-skin(lighten( desaturate( adjust-hue( $text-color, -6deg ), 3 ), 4 ), $skin-color),        // #323842
        text-10: set-light-skin(lighten( desaturate( $text-color, 2 ), 2 ), $skin-color),                            // #2e323e
        text-11: set-light-skin($text-color, $skin-color),                                                           // #282c38
        text-12: set-light-skin(darken( saturate( adjust-hue( $text-color, 1deg ), 3 ), 3 ), $skin-color),           // #212532
        text-13: set-light-skin(darken( saturate( adjust-hue( $skin-color, 1deg ), 9 ), 6 ), $skin-color),           // #181C29
        text-14: set-light-skin(darken( saturate( adjust-hue( $text-color, 1deg ), 12 ), 9 ), $skin-color),          // #121623
        text-15: set-light-skin(darken( saturate( adjust-hue( $text-color, -15deg ), 13 ), 11 ), $skin-color),       // #0e141a
        text-16: set-color($skin-color),                                                                             // #000000
);

@each $name, $color in $colors {
  $colors: map-merge($colors, (
          #{$name + '-hover'}: set-lightness($color, 10),
          #{$name + '-sc'}: set-color($color),
          #{$name + '-rgb'}: #{red($color), green($color), blue($color)},
          #{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))},
  ));
}

@if(lightness($skin-color) > 50){
  $color-white: #000000 !global !default;
  $color-black: #ffffff !global !default;

  $brand-color-1-bg-hover-active:  darken($brand-color-1-bg,  5%);
  $brand-color-1-txt-hover-active: darken($brand-color-1-txt, 5%);
  $brand-color-2-bg-hover-active:  darken($brand-color-2-bg,  5%);
  $brand-color-2-txt-hover-active: darken($brand-color-2-txt, 5%);
}@else{
  $color-white: #ffffff !global !default;
  $color-black: #000000 !global !default;

  $brand-color-1-bg-hover-active:  lighten($brand-color-1-bg,  5%);
  $brand-color-1-txt-hover-active: lighten($brand-color-1-txt, 5%);
  $brand-color-2-bg-hover-active:  lighten($brand-color-2-bg,  5%);
  $brand-color-2-txt-hover-active: lighten($brand-color-2-txt, 5%);
}

$font-f: 'Roboto', sans-serif !default;


@mixin side($number) {
  html:not([dir="rtl"]) & {
    right: $number;
  }
  html[dir="rtl"] & {
    left: $number;
  }
}

