@import "skin";

//---------- Betslip Component Start -------------
.pmu-betslip-wrapper {
  margin-top: 10px
}

.pmu-sb-ct-h-r {
  display: inline-block;
  height: 100%;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }
}

.pmu-sb-ct-h-u-chf {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 30px;
}

.pmu-b-b {
  margin-top: 2px;
  padding: 30px 10px 16px 10px;
  background: c(skin-10);
}

.pmu-b-b-ct {

}

.pmu-b-b-ct-s-t {
  display: block;
  font-weight: normal;
  font-size: 10px;
  color: c(text-3);
  padding-bottom: 16px;
  border-bottom: 1px solid rgba($color-white, 0.2);
}

.pmu-b-s-bet-type {
  padding-top: 12px;
}

.pmu-b-s-bet-type-t {

}

.pmu-b-s-bet-type-t-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: c(text-2);

  &.t-1 {
    position: relative;
    text-transform: uppercase;

    [dir=ltr] & {
      padding-right: 8px;
      margin-right: 8px;
    }

    [dir=rtl] & {
      padding-left: 8px;
      margin-left: 8px;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: rgba($color-white, 0.2);

      [dir=ltr] & {
        right: 0;
      }
      [dir=rtl] & {
        left: 0;
      }
    }
  }
  &.t-2 {
  }
}

.pmu-b-s-bet-type-t-t-icon {
  display: inline-block;
  vertical-align: top;
  padding: 0 8px;
  margin-top: 2px;
}

.pmu-b-s-bet-type-b-t {
  padding-top: 16px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 14px;
}

.pmu-b-s-bet-order {
  margin-top: 8px;
  padding: 8px 0;
  border-top: 1px solid rgba($color-white, 0.2);
  border-bottom: 1px solid rgba($color-white, 0.2);
}

.pmu-b-s-bet-o-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: c(text-2);
  text-transform: uppercase;
}

.pmu-b-s-bet-a-o {
  padding-top: 14px;
}

.pmu-b-s-bet-a-o-t {
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-transform: uppercase;
  line-height: 14px;
}

.pmu-b-s-bet-a-o-t-s {
  display: inline-block;
  vertical-align: top;

  [dir=ltr] & {
    margin-left: 4px;
  }

  [dir=rtl] & {
    margin-right: 4px;
  }
}

.pmu-b-d {
  padding-top: 14px;
}

.pmu-b-d-t {
  display: block;
  height: 31px;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 30px;
  border-bottom: 1px solid rgba($color-white, 0.2);

  [dir=ltr] & {
    padding-right: 24px;
  }
  [dir=rtl] & {
    padding-left: 24px;
  }
}

.pmu-b-d-t-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: 14px;
  color: c(text-10);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition-property: color, transform;
  transition-duration: $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 2px;
  }
  [dir=rtl] & {
    left: 2px;
  }

  &:hover,
  &.active {
    color: c(text-1)
  }
  &.active {
    transform: rotateX(180deg);
  }
}

.pmu-b-d-i {
  height: 31px;
  border-bottom: 1px solid rgba($color-white, 0.2);
  position: relative;
  font-weight: normal;
  font-size: 10px;
  color: c(text-3);
  line-height: 30px;

  [dir=ltr] & {
    padding-right: 24px;
  }
  [dir=rtl] & {
    padding-left: 24px;
  }
}

.pmu-b-d-i-n {
  display: inline-block;
  vertical-align: top;

  [dir=ltr] & {
    padding-right: 8px;
  }
  [dir=rtl] & {
    padding-left: 8px;
  }
}

.pmu-b-d-i-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: c(text-10);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 5px;
  }
  [dir=rtl] & {
    left: 5px;
  }

  &:hover {
    color: $close-remove-color;
  }
}

.pmu-b-d-opts {

}

.pmu-b-d-o {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($color-white, 0.2);

  &:last-child {
    border-bottom: none;
  }
}

.pmu-b-d-o-t {
  display: block;
  padding-bottom: 6px;
  font-weight: normal;
  font-size: 10px;
  color: c(text-2);
  line-height: 12px;
}

.pmu-b-d-o-i {
  height: 26px;
  box-sizing: content-box;
  position: relative;
}

.pmu-b-d-o-i,
.pmu-b-s-cmb-c {
  > .md-restyle.select {
    > div > div > div {
      line-height: 26px;
    }
  }
}

.pmu-b-d-o-i-ct {
  height: 100%;
  position: relative;
  padding: 0 10px;
  border: 1px solid rgba($color-white, 0.2);
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 24px;

  [dir=ltr] & {
    padding-right: 62px;
  }
  [dir=rtl] & {
    padding-left: 62px;
  }
}

.pmu-b-d-o-i-ct-m-w {
  display: inline-block;
  vertical-align: top;
  width: 62px;
  height: 100%;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    right: 0;
    border-left: 1px solid rgba($color-white, 0.2);
  }
  [dir=rtl] & {
    left: 0;
    border-right: 1px solid rgba($color-white, 0.2);
  }
}

.pmu-b-d-o-i-ct-m {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pmu-b-d-o-i-ct-m-backface {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-b-d-o-i-ct-m-i {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  background: transparent;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
}

.pmu-b-d-o-i-ct-m-icon {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  font-size: 16px;
  color: c(text-2);
  transition-property: color, top, bottom, opacity;
  transition-duration: $transition-length;
  cursor: pointer;
  z-index: 2;


  &.left {
    left: 38px;
  }

  &.right {
    right: 40px;
  }

  &:hover {
    color: c(text-3);
  }
}

.pmu-b-s-cmb {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  height: 26px;
  margin-top: 10px;
}

.pmu-b-s-cmb-c {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  height: 100%;
  position: relative;

  &.combinations {
    [dir=ltr] & {
      border-right: 8px solid transparent;
    }
    [dir=rtl] & {
      border-left: 8px solid transparent;
    }

    > .pmu-b-s-cmb-ch {
      box-shadow: 0 0 0 1px rgba($color-white, 0.2) inset;
    }
  }
}

.pmu-b-s-cmb-ch {
  display: block;
  vertical-align: top;
  height: 100%;
  position: relative;
  font-weight: normal;
  font-size: 11px;
  color: c(text-2);
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir=ltr] & {
    padding: 0 36px 0 10px;
  }
  [dir=rtl] & {
    padding: 0 10px 0 36px;
  }
}

.pmu-b-s-cmb-ch-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 10px;
  }
  [dir=rtl] & {
    left: 10px;
  }

  &:hover {
    color: c(text-1);
  }
}

.pmu-b-s-c-acts {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 26px;
  padding-top: 16px;
  box-sizing: content-box;
}

.pmu-b-s-c-acts-c {
  display: table-cell;
  vertical-align: middle;
  width: 50%;

  &.erase {
    [dir=ltr] & {
      text-align: left;
    }
    [dir=rtl] & {
      text-align: right;
    }
  }
  &.do-bet {
    [dir=ltr] & {
      text-align: right;
    }
    [dir=rtl] & {
      text-align: left;
    }
  }
}

.pmu-b-s-c-a-e {
  display: inline-block;
  position: relative;
  border-bottom: 1px solid c(text-10);
  font-weight: normal;
  font-size: 11px;
  color: c(text-10);
  line-height: 12px;
  transition-property: color, border;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover {
    color: c(text-1);
    border-color: c(text-1);
  }

  &.disabled {
    pointer-events: none;
  }
}

.pmu-b-s-c-a-b {
  display: inline-block;
  min-width: 100px;
  height: 26px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: $brand-color-1-bg;
  font-weight: normal;
  font-size: 12px;
  color: $brand-color-1-txt;
  text-transform: uppercase;
  transition-property: color, background;
  transition-duration: $transition-length;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    pointer-events: none;
    -webkit-user-select: none;
  }

  &:hover {
    background: lighten($brand-color-1-bg, 10%);
  }
}

.pmu-b-s-m-b-w {
  padding-top: 12px;

  &.fail {
    > .pmu-b-s-m-b-t {
      color: $status-color-fail-bg;
    }
  }
  &.pending {
    > .pmu-b-s-m-b-t {
      color: $status-color-pending-bg;
    }
  }
  &.success {
    > .pmu-b-s-m-b-t {
      color: $status-color-success-bg;
    }
  }
}

.pmu-b-s-m-b-t {
  display: block;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}

.pmu-b-s-m-b-icon {
  display: inline-block;
  vertical-align: top;

  [dir=ltr] & {
    padding-right: 4px;
  }
  [dir=rtl] & {
    padding-left: 4px;
  }
}

//---------- Betslip Component End ---------------

@media screen and (max-width: 979px) {
  .pmu-betslip-wrapper {
    margin-top: 5px;
  }
  .pmu-b-d-t-icon,
  .pmu-b-d-i-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  .pmu-b-d-t,
  .pmu-b-d-i {
    height: 41px;
    line-height: 40px;
  }
  .pmu-b-d-o-i {
    height: 41px;

    > div > div > div {
      &:after {
        width: 18px !important;
        height: 18px !important;;
        font-size: 18px !important;
      }
    }
    > div > div > div > div {
      line-height: 40px !important;
    }
  }
  .pmu-b-s-c-a-b {
    height: 32px;
  }
  .pmu-b-d-o-i-ct {
    line-height: 40px;
  }
}
