@import "skin";

$timeLineRowsCalculationCount: 10;

@mixin RowsCalculation($count: 0, $height-1: 0, $height-2: 0, $grow: 0, $time-indicator-height: 0) {

  @for $i from 1 through $count {
    &.rows-#{$i} {
      > .pmu-r-slider-w {
        height: $height-1;

        > .pmu-slide-scroll-c {
          height: $height-2;
        }
      }

      .pmu-real-time-v {
        height: $time-indicator-height;
      }
    }

    $height-1: $height-1 + $grow;
    $height-2: $height-2 + $grow;
    $time-indicator-height: $time-indicator-height + $grow;
  }
}

// ---------- Racing Program Start ----------
.pmu-racing-program-wrapper {

}

.pmu-r-p-b {
  margin: 2px 0 10px;
}

.pmu-r-c-row-h-t-rn,
.pmu-r-c-row-h-b-rn {
  display: inline-block;
  width: 24px;
  height: 25px;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  line-height: 23px;
  position: absolute;


  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}


.pmu-r-c-row-h-b-rn {
  background: c(skin-9);
  color: c(text-1);
  bottom: 0;
}

.pmu-r-c-row-h-t-rn {
  background: $brand-color-1-bg;
  top: 0;
  &.finished {
    background: c(skin-7);
  }
  color: $brand-color-1-txt;
}

.pmu-r-c-row-h-ct {
  height: 100%;
  padding: 6px 10px;
  cursor: pointer;
}

.pmu-r-c-row-h-ct-t {
  font-weight: normal;
  font-size: 11px;
  color: c(text-2);
  letter-spacing: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-r-c-row-h-ct-icon {
  display: inline-block;
  position: absolute;
  font-size: 16px;
  top: 5px;
  color: c(text-4);
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 2px;
  }
  [dir=rtl] & {
    left: 2px;
    transform: rotateY(180deg);
  }

  &:hover {
    color: c(text-1);
  }
}

.pmu-r-c-row-h-ct-sb-t {
  display: none;
  padding-top: 4px;
  font-weight: normal;
  font-size: 9px;
  color: c(text-3);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-r-c-row-h-d {
  display: inline-block;
  width: 100%;
  margin-top: 12px;
  font-size: 0;
}

.pmu-r-c-row-h-d-i {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 10px;
  color: c(text-2);
  line-height: 12px;

  [dir=ltr] & {
    margin-right: 8px;
  }
  [dir=rtl] & {
    margin-left: 8px;
  }
  &:nth-child(2) {
    margin: 0;
  }

  > .r-c-icon {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;

    [dir=ltr] & {
      margin-right: 4px;
    }
    [dir=rtl] & {
      margin-left: 4px;
    }
  }
}

.pmu-r-c-row-h-d-icons {
  display: inline-block;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }
}

.pmu-r-c-row-h-d-icon {
  display: inline-block;

  [dir=ltr] & {
    margin-right: 4px;
  }
  [dir=rtl] & {
    margin-left: 4px;
  }

  &:last-child {
    margin: 0;
  }
}

.pmu-h-r-event-i {
  display: flex;
  min-height: 36px;
  min-width: 36px;
  max-width: 180px;
  border-radius: 2px;
  background: c(skin-8);
  box-shadow: 0 0 5px 0 rgba($color-black, 0.8);
  position: absolute;
  z-index: 1;
  transition: background $transition-length;
  overflow: hidden;
  cursor: pointer;


  [dir=ltr] & {
    padding-left: 24px;
    margin-right: 5px;
  }
  [dir=rtl] & {
    padding-right: 24px;
    margin-left: 5px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.active {
    background: c(skin-9);
  }

  &.active {
    box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  }

  &.finished {
    > .pmu-h-r-event-rn {
      background: c(skin-7);
    }
    > .pmu-h-r-event-i-ct {
      background: c(skin-12);
    }
  }

  &.time-zone {
    width: 104px;
    [dir=ltr] & {
      padding-right: 24px;
    }
    [dir=rtl] & {
      padding-left: 24px;
    }
  }
}

.pmu-h-r-event-rn {
  display: inline-block;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  background: $brand-color-1-bg;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  color: $brand-color-1-txt;
  text-align: center;
  line-height: 40px;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }

  &.fullWidth{
    width: 100%;
  }
}

.pmu-h-r-event-i-ct {
  height: 100%;
  position: relative;
}

.pmu-h-r-event-i-ct-t {
  display: none;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.t-1 {
    font-size: 12px;
    color: c(text-1);
  }
  &.t-2 {
    padding-top: 4px;
    font-size: 10px;
    color: c(text-2);
  }
  &.t-3 {
    padding-top: 2px;
    font-size: 10px;
    color: c(text-2);
  }
}

.pmu-h-r-event-i-ct-c {
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 9px;
  line-height: 10px;
}

.pmu-h-r-event-i-ct-c-t {
  display: none;
  font-weight: normal;
  font-size: 9px;
  color: c(text-3);
  line-height: 12px;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }
}

.pmu-h-r-event-i-d-t {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  font-weight: normal;
  font-size: 10px;
  color: c(text-4);
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  background: c(skin-6);

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-time-line-info {
  min-width: 200px;
  max-width: 250px;
  position: absolute;
  z-index: 100;
  border-radius: 4px;
  background: c(skin-9);

  &.finished {
    > .pmu-t-l-i-b {
      opacity: 0.4;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
  }

  &.to-top:before,
  &.to-bot:before {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.to-top:before {
    bottom: 100%;
    border-width: 0 4px 5px 4px;
    border-color: transparent transparent c(skin-6) transparent;
  }

  &.to-bot:before {
    top: 100%;
    border-width: 5px 4px 0 4px;
    border-color: c(skin-6) transparent transparent transparent;
  }

  &.to-left:before,
  &.to-right:before {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  &.to-left:before {
    [dir=ltr] & {
      right: 100%;
      border-width: 4px 5px 4px 0;
      border-color: transparent c(skin-6) transparent transparent;
    }
    [dir=rtl] & {
      left: 100%;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent c(skin-6);
    }
  }
  &.to-right:before {
    [dir=ltr] & {
      left: 100%;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent c(skin-6);
    }
    [dir=rtl] & {
      right: 100%;
      border-width: 4px 5px 4px 0;
      border-color: transparent c(skin-6) transparent transparent;
    }
  }
}

.pmu-t-l-i-h {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(#ffffff, 0.2);;
}

.pmu-t-l-i-h-t {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: c(text-3);

  &.t-1 {
    font-weight: bold;
    text-transform: uppercase;
  }
  &.t-2 {
    padding-top: 12px;
    font-weight: normal;
  }
  &.t-3 {
    padding-top: 2px;
    font-weight: normal;
  }
}

.pmu-t-l-i-b {
  padding: 12px 16px;
}

.pmu-t-l-i-b-i {
  display: inline-block;
  vertical-align: top;
  margin: 2px;
}

// ---------- Racing Program End ----------

// ----------- TimeLine Start -----------
.pmu-r-slider-w {
  width: 100%;
  overflow: hidden;
  position: relative;
  //background: $skin-palette-6;
}

.pmu-a-contain {
  position: absolute;
  top: 0;
  height: 100%;
  min-width: 100%;
  padding-top: 30px;

  [dir=ltr] & {
    left: 0;
  }

  [dir=rtl] & {
    right: 0;
  }
}

.pmu-slide-scroll-c {
  width: 100%;
  height: 310px;
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow-x: auto;
  overflow-y: hidden;
}

.pmu-top-row {
  width: 100%;
  height: 30px;
  margin: 0;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-time-list-row {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background: c(skin-7);
}

.pmu-racing-pro-list {
  width: 100%;

  &.detailed {
    .pmu-r-c-row {
      height: 84px;
    }

    @include RowsCalculation($timeLineRowsCalculationCount, 108px, 126px, 86px, 80px);

    .pmu-r-c-row-h-t-rn {
      height: 52px;
      line-height: 52px;
    }

    .pmu-r-c-row-h-b-rn {
      height: 30px;
      line-height: 30px;
    }

    .pmu-r-c-row-h-d {
      margin-top: 4px;
      padding: 0;
    }
    .pmu-r-c-row-h-d-icons {
      display: block;
      float: none;
      border-top: 1px solid c(skin-4);
      margin-top: 7px;
      padding-top: 6px;
    }

    .pmu-h-r-event-i {
      //width: 254px;
    }
    .pmu-h-r-event-rn {
      line-height: 72px;
    }
    .pmu-h-r-event-i-ct {
      padding: 4px 8px;
      width: 100%;
    }
    .pmu-h-r-event-i-ct-c {
      width: 100%;
      position: static;
      //margin-top: 4px;
      margin-top: 10px;
    }
    .pmu-r-c-row-h-ct-sb-t,
    .pmu-h-r-event-i-ct-t,
    .pmu-h-r-event-i-ct-c-t {
      display: block;
    }
    .pmu-h-r-event-i-d-t {
      line-height: 66px;
    }
  }

  @include RowsCalculation($timeLineRowsCalculationCount, 82px, 100px, 52px, 56px);
}

.pmu-r-c-row {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  white-space: nowrap;
  height: 52px;
  border-top: 2px solid transparent;
  position: relative;

  &:nth-child(2) {
    z-index: 34;
  }

  &.finished {
    .pmu-r-c-row-h-t-rn {
      background: c(skin-10);
    }
    .pmu-r-c-row-h-b-rn {

    }
  }
}

.pmu-r-c-row-h {
  display: inline-block;
  vertical-align: top;
  width: 220px;
  height: 100%;
  position: sticky;
  z-index: 40;
  background: c(skin-8);

  [dir=ltr] & {
    left: 0;
    padding-left: 24px;
    box-shadow: 1px 0px 0px 1px $body-background;
  }

  [dir=rtl] & {
    right: 0;
    padding-right: 24px;
    box-shadow: -1px 0px 0px 1px $body-background;
  }
}

.pmu-time-l-event-r {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  position: relative;
  z-index: 33;
  //background: rgba(c(skin-4), 0.8);  // need to delate comment
}

.pmu-finish-time-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: c(skin-6);

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-real-time-v {
  position: absolute;
  top: -6px;
  width: 1px;
  box-sizing: content-box;
  z-index: 66;
  background: $brand-color-1-bg;

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }

  > span {
    font-size: 12px;
    line-height: 22px;
    background: $brand-color-1-bg;
    color: $brand-color-1-txt;
    display: inline-block;
    padding: 0 5px;
    border-radius: 3px;
    position: absolute;
    top: -22px;

    [dir=ltr] & {
      right: 0;
      transform: translateX(50%);
    }
    [dir=rtl] & {
      left: 0;
      transform: translateX(-50%);
    }
  }
}

.pmu-r-slider-h {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    left: 0;
  }

  [dir=ltr] & {
    right: 0;
  }
}

.pmu-r-slider-h-c {
  display: table-cell;
  vertical-align: top;
  position: relative;

  &:nth-child(1) {
    width: 220px;
  }
  &:nth-child(2) {

  }
}

.pmu-arrows-holder {
  width: 100%;
  height: 30px;
  position: relative;
  top: 0;
  z-index: 67;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-l-s-icon, .pmu-r-s-icon {
  width: 30px;
  height: 30px;
  background: c(skin-7);
  font-size: 20px;
  text-align: center;
  color: c(text-3);
  cursor: pointer;
  transition: background 240ms, color 240ms;

  [dir=ltr] & {
    float: left;
  }
  [dir=rtl] & {
    float: right;

    transform: rotate(180deg);
  }

  &:before {
    display: inline-block;
    width: 100%;
    line-height: 30px;
  }

  &:hover {
    background: c(skin-5);
    color: c(text-1);
  }
}

.pmu-r-s-icon {
  position: absolute;
  [dir=ltr] & {
    right: -3px;
  }
  [dir=rtl] & {
    left: -3px;
  }
}

.pmu-date-p-filter {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  line-height: 30px;
  width: 100%;
  height: 30px;
  margin: 0;
  background: c(skin-7);
  position: relative;
  z-index: 99;

  [dir=ltr] & {
    box-shadow: 1px 0px 0 1px c(skin-9);
  }
  [dir=rtl] & {
    box-shadow: -1px 0px 0 1px c(skin-9);
  }
}

.pmu-t-filter {
  display: inline-block;
  height: 100%;

  [dir=ltr] & {
    float: left;
  }
  [dir=rtl] & {
    float: right;
  }

  //Material Override Start
  //Custom for this place

  > div > div > div {
    line-height: 30px;
  }

  //Material Override End
}

.pmu-date-picker-w {
  display: inline-block;
  height: 100%;
  width: 100%;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }

  //Material Override Start
  //Custom for this place
  div {
    margin-top: 0px !important;
  }
  input {
    display: none !important;
  }
  label {
    font-size: 16px;
    color: white;
    top: 8px;

    [dir=ltr] & {
      left: 8px;
    }
    [dir=rtl] & {
      right: 8px;
    }
  }
  button {
    margin-top: 0px;
    &:hover {
      background: c(skin-5);

      > span:nth-child(1) {
        &:before {
          color: c(text-1);
        }
      }
    }
  }
  //Material Override End
}

.pmu-time-section {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  height: 100%;
  position: relative;
  z-index: 33;
  background: c(skin-8);
  &:first-child {
    z-index: 55;
    > .time-setion:first-child {
      > span {
        [dir=ltr] & {
          transform: translateX(5px);
        }
        [dir=rtl] & {
          transform: translateX(-5px);
        }
      }
    }
  }
}

.pmu-time-section-inner {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  box-shadow: -1px 0 0 c(skin-10) inset;
  position: relative;
  padding: 5px;
}

.pmu-time-section-inner-i-t {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  top: -32px;
  font-size: 12px;
  line-height: 30px;
  color: c(text-3);

  [dir=ltr] & {
    left: 0;
    transform: translateX(-50%);
  }
  [dir=rtl] & {
    right: 0;
    transform: translateX(50%);
  }
}

.pmu-time-section-inner-i {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

// ----------- TimeLine End -----------
// 
// temp solution

.temp-width-bet-type {
  min-width: 80px;
}
