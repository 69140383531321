@import "~sass/settings";

@font-face {
  font-family: 'BetConstruct-Icons';
  src:  url('#{$icons-root}BetConstruct-Icons.eot#{$version-control}');
  src:  url('#{$icons-root}BetConstruct-Icons.eot#{$version-control}') format('embedded-opentype'),
        url('#{$icons-root}BetConstruct-Icons.ttf#{$version-control}') format('truetype'),
        url('#{$icons-root}BetConstruct-Icons.woff#{$version-control}') format('woff'),
        url('#{$icons-root}BetConstruct-Icons.svg#{$version-control}#BetConstruct-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="bc-i-"], [class*=" bc-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BetConstruct-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bc-i-success:before {
  content: "\e95e";
}
.bc-i-fail:before {
  content: "\e95f";
}
.bc-i-attention:before {
  content: "\e960";
}
.bc-i-my-bets-up:before {
  content: "\e95c";
}
.bc-i-my-bets-down:before {
  content: "\e95d";
}
.bc-i-pmu-couple-place:before {
  content: "\e950";
}
.bc-i-pmu-simple-place:before {
  content: "\e957";
}
.bc-i-pmu-couple-gagnant:before {
  content: "\e907";
}
.bc-i-pmu-couple-ordre:before {
  content: "\e92d";
}
.bc-i-pmu-multi:before {
  content: "\e951";
}
.bc-i-pmu-pick-five:before {
  content: "\e952";
}
.bc-i-pmu-quante-plus:before {
  content: "\e953";
}
.bc-i-pmu-quarte-plus:before {
  content: "\e954";
}
.bc-i-pmu-quinte-plus:before {
  content: "\e955";
}
.bc-i-pmu-simple-gagnant:before {
  content: "\e956";
}
.bc-i-pmu-tierce:before {
  content: "\e958";
}
.bc-i-pmu-trio-ordre:before {
  content: "\e959";
}
.bc-i-pmu-trio:before {
  content: "\e95a";
}
.bc-i-pmu-simple-placed-e:before {
  content: "\e908";
}
.bc-i-pmu-simple-gagnant-e:before {
  content: "\e909";
}
.bc-i-pmu-quante-plus-e:before {
  content: "\e929";
}
.bc-i-pmu-multi-e:before {
  content: "\e906";
}
.bc-i-pmu-trio-e:before {
  content: "\e905";
}
.bc-i-pmu-quarte-plus-e:before {
  content: "\e904";
}
.bc-i-pmu-tierce-e:before {
  content: "\e903";
}
.bc-i-pmu-couple-placed-e:before {
  content: "\e902";
}
.bc-i-pmu-couple-gagnant-e:before {
  content: "\e901";
}
.bc-i-login:before {
  content: "\e624";
}
.bc-i-pmu-couple-ordre-e:before {
  content: "\e900";
}
.bc-i-handshake:before {
  content: "\e95b";
}
.bc-i-info-2:before {
  content: "\e633";
}
.bc-i-add:before {
  content: "\e145";
}
.bc-i-remove:before {
  content: "\e15b";
}
.bc-i-burger:before {
  content: "\e5d2";
}
.bc-i-volume-down:before {
  content: "\e04d";
}
.bc-i-volume-mute:before {
  content: "\e04e";
}
.bc-i-volume-off:before {
  content: "\e04f";
}
.bc-i-volume-up:before {
  content: "\e050";
}
.bc-i-search-header-new1:before {
  content: "\e6d5";
}
.bc-i-thumb-down:before {
  content: "\e8db";
}
.bc-i-thumb-up:before {
  content: "\e8dc";
}
.bc-i-search:before {
  content: "\e6d6";
}
.bc-i-more-horiz:before {
  content: "\e5d3";
}
.bc-i-more-vert:before {
  content: "\e5d4";
}
.bc-i-zmw:before {
  content: "\e9c4";
}
.bc-i-zar:before {
  content: "\e9c5";
}
.bc-i-xof:before {
  content: "\e9c6";
}
.bc-i-vnd:before {
  content: "\e9c7";
}
.bc-i-vef:before {
  content: "\e9c8";
}
.bc-i-uzs:before {
  content: "\e9c9";
}
.bc-i-uyu:before {
  content: "\e9ca";
}
.bc-i-ugx:before {
  content: "\e9cb";
}
.bc-i-uah:before {
  content: "\e9cc";
}
.bc-i-tzs:before {
  content: "\e9cd";
}
.bc-i-ttd:before {
  content: "\e9ce";
}
.bc-i-tnd:before {
  content: "\e9cf";
}
.bc-i-tjs:before {
  content: "\e9d0";
}
.bc-i-thb:before {
  content: "\e9d1";
}
.bc-i-syp:before {
  content: "\e9d2";
}
.bc-i-sll:before {
  content: "\e9d3";
}
.bc-i-sgd:before {
  content: "\e9d4";
}
.bc-i-sar:before {
  content: "\e9d5";
}
.bc-i-rsd:before {
  content: "\e9d6";
}
.bc-i-ron:before {
  content: "\e9d7";
}
.bc-i-qar:before {
  content: "\e9d8";
}
.bc-i-pyg:before {
  content: "\e9d9";
}
.bc-i-pln:before {
  content: "\e9da";
}
.bc-i-pen:before {
  content: "\e9db";
}
.bc-i-pab:before {
  content: "\e9dc";
}
.bc-i-ngn:before {
  content: "\e9dd";
}
.bc-i-mzn:before {
  content: "\e9de";
}
.bc-i-myr:before {
  content: "\e9df";
}
.bc-i-mxn:before {
  content: "\e9e0";
}
.bc-i-mkd:before {
  content: "\e9e1";
}
.bc-i-mdl:before {
  content: "\e9e2";
}
.bc-i-mad:before {
  content: "\e9e3";
}
.bc-i-lkr:before {
  content: "\e9e4";
}
.bc-i-lbp:before {
  content: "\e9e5";
}
.bc-i-kzt:before {
  content: "\e9e6";
}
.bc-i-kgs:before {
  content: "\e9e7";
}
.bc-i-kes:before {
  content: "\e9e8";
}
.bc-i-jod:before {
  content: "\e9e9";
}
.bc-i-jmd:before {
  content: "\e9ea";
}
.bc-i-irr:before {
  content: "\e9eb";
}
.bc-i-iqd:before {
  content: "\e9ec";
}
.bc-i-inr:before {
  content: "\e9ed";
}
.bc-i-ils:before {
  content: "\e9ee";
}
.bc-i-idr:before {
  content: "\e9ef";
}
.bc-i-huf:before {
  content: "\e9f0";
}
.bc-i-htg:before {
  content: "\e9f1";
}
.bc-i-hrk:before {
  content: "\e9f2";
}
.bc-i-hnl:before {
  content: "\e9f3";
}
.bc-i-hkd:before {
  content: "\e9f4";
}
.bc-i-gtq:before {
  content: "\e9f5";
}
.bc-i-gmd:before {
  content: "\e9f6";
}
.bc-i-gel:before {
  content: "\e9f7";
}
.bc-i-dop:before {
  content: "\e9f8";
}
.bc-i-czk:before {
  content: "\e9f9";
}
.bc-i-crc:before {
  content: "\e9fa";
}
.bc-i-cop:before {
  content: "\e9fb";
}
.bc-i-clp:before {
  content: "\e9fc";
}
.bc-i-cad:before {
  content: "\e9fd";
}
.bc-i-byr:before {
  content: "\e9fe";
}
.bc-i-bsd:before {
  content: "\e9ff";
}
.bc-i-brl:before {
  content: "\ea00";
}
.bc-i-bob:before {
  content: "\ea01";
}
.bc-i-bmd:before {
  content: "\ea02";
}
.bc-i-bgn:before {
  content: "\ea03";
}
.bc-i-bdt:before {
  content: "\ea04";
}
.bc-i-bam:before {
  content: "\ea05";
}
.bc-i-azn:before {
  content: "\ea06";
}
.bc-i-aoa:before {
  content: "\ea08";
}
.bc-i-aed:before {
  content: "\ea09";
}
.bc-i-amd:before {
  content: "\ea0a";
}
.bc-i-pound:before {
  content: "\e9b7";
}
.bc-i-australian-dollar:before {
  content: "\e9b8";
}
.bc-i-swiss-franc:before {
  content: "\e9b9";
}
.bc-i-euro:before {
  content: "\e9ba";
}
.bc-i-yen:before {
  content: "\e9bb";
}
.bc-i-lira:before {
  content: "\e9bc";
}
.bc-i-south-korean-won:before {
  content: "\e9bd";
}
.bc-i-swedish-krona:before {
  content: "\e9be";
}
.bc-i-peso:before {
  content: "\e9bf";
}
.bc-i-cent:before {
  content: "\e9c0";
}
.bc-i-bitcoin:before {
  content: "\e9b4";
}
.bc-i-ruble:before {
  content: "\e9b5";
}
.bc-i-dollar:before {
  content: "\e9b6";
}
.bc-i-discipline-obstacle:before {
  content: "\e920";
}
.bc-i-discipline-plat:before {
  content: "\e921";
}
.bc-i-discipline-trot:before {
  content: "\e922";
}
.bc-i-cheval:before {
  content: "\e91b";
}
.bc-i-discipline-trot-attele:before {
  content: "\e923";
}
.bc-i-discipline-trot-monte:before {
  content: "\e924";
}
.bc-i-picto-pe:before {
  content: "\e90d";
}
.bc-i-oeillere-austral:before {
  content: "\e90a";
}
.bc-i-programme-details:before {
  content: "\e90f";
}
.bc-i-meteo-p1:before {
  content: "\e939";
}
.bc-i-meteo-p4:before {
  content: "\e93a";
}
.bc-i-meteo-p8:before {
  content: "\e93b";
}
.bc-i-meteo-p8c:before {
  content: "\e93c";
}
.bc-i-meteo-p10:before {
  content: "\e93d";
}
.bc-i-meteo-p11:before {
  content: "\e93e";
}
.bc-i-meteo-p13:before {
  content: "\e940";
}
.bc-i-meteo-p13a:before {
  content: "\e941";
}
.bc-i-meteo-p14:before {
  content: "\e942";
}
.bc-i-meteo-p18:before {
  content: "\e946";
}
.bc-i-meteo-p18a:before {
  content: "\e947";
}
.bc-i-meteo-p15:before {
  content: "\e943";
}
.bc-i-meteo-p16:before {
  content: "\e944";
}
.bc-i-meteo-p17:before {
  content: "\e945";
}
.bc-i-meteo-p12:before {
  content: "\e93f";
}
.bc-i-spot:before {
  content: "\e912";
}
.bc-i-course-annulee:before {
  content: "\e91d";
}
.bc-i-course-en-cours:before {
  content: "\e91e";
}
.bc-i-depart:before {
  content: "\e91f";
}
.bc-i-distance:before {
  content: "\e925";
}
.bc-i-dtlo:before {
  content: "\e926";
}
.bc-i-encours:before {
  content: "\e927";
}
.bc-i-heart:before {
  content: "\e932";
}
.bc-i-help-11:before {
  content: "\e933";
}
.bc-i-mail-forward:before {
  content: "\e938";
}
.bc-i-min3:before {
  content: "\e948";
}
.bc-i-move:before {
  content: "\e94a";
}
.bc-i-next-course:before {
  content: "\e94b";
}
.bc-i-next-programme:before {
  content: "\e94c";
}
.bc-i-notdef:before {
  content: "\e94d";
}
.bc-i-user:before {
  content: "\e915";
}
.bc-i-user-2:before {
  content: "\e916";
}
.bc-i-plus-joues:before {
  content: "\e90e";
}
.bc-i-sponsorship:before {
  content: "\e911";
}
.bc-i-personnes:before {
  content: "\e90c";
}
.bc-i-trash:before {
  content: "\e913";
}
.bc-i-trash-filled:before {
  content: "\e914";
}
.bc-i-delete-1:before {
  content: "\e872";
}
.bc-i-delete-2:before {
  content: "\e94f";
}
.bc-i-arrow-left-2:before {
  content: "\e317";
}
.bc-i-arrow-right-2:before {
  content: "\e318";
}
.bc-i-arrow-top-2:before {
  content: "\e319";
}
.bc-i-arrow-bottom-2:before {
  content: "\e31a";
}
.bc-i-arrow-left:before {
  content: "\e5d0";
}
.bc-i-arrow-right:before {
  content: "\e5d1";
}
.bc-i-arrow-top:before {
  content: "\e5ce";
}
.bc-i-arrow-bottom:before {
  content: "\e5cf";
}
.bc-i-cadeau:before {
  content: "\e918";
}
.bc-i-gift:before {
  content: "\e931";
}
.bc-i-gift1:before {
  content: "\e8b2";
}
.bc-i-calendar-2:before {
  content: "\e94e";
}
.bc-i-calendar-3:before {
  content: "\e91a";
}
.bc-i-calendar-1:before {
  content: "\e616";
}
.bc-i-favorite-2:before {
  content: "\e87d";
}
.bc-i-favorite-unselected-2:before {
  content: "\e87e";
}
.bc-i-star-selected:before {
  content: "\e838";
}
.bc-i-star-unselected:before {
  content: "\e83a";
}
.bc-i-location:before {
  content: "\e8b4";
}
.bc-i-location-off:before {
  content: "\e0c7";
}
.bc-i-mic:before {
  content: "\e029";
}
.bc-i-mic-off:before {
  content: "\e02b";
}
.bc-i-lock-1:before {
  content: "\e897";
}
.bc-i-lock-2:before {
  content: "\e899";
}
.bc-i-lock-unclocked-2:before {
  content: "\e898";
}
.bc-i-cloud-1:before {
  content: "\e2c3";
}
.bc-i-cloud-2:before {
  content: "\e42e";
}
.bc-i-play-arrow:before {
  content: "\e037";
}
.bc-i-pause:before {
  content: "\e034";
}
.bc-i-lens:before {
  content: "\e3fa";
}
.bc-i-play-circle:before {
  content: "\e038";
}
.bc-i-cancel:before {
  content: "\e5c9";
}
.bc-i-close-remove:before {
  content: "\e5cd";
}
.bc-i-checkbox-checked:before {
  content: "\e834";
}
.bc-i-checkbox-unchecked:before {
  content: "\e835";
}
.bc-i-checked:before {
  content: "\e5ca";
}
.bc-i-info-1:before {
  content: "\e935";
}
.bc-i-help-1:before {
  content: "\e8fe";
}
.bc-i-help-2:before {
  content: "\e888";
}
.bc-i-warning-outlines:before {
  content: "\e917";
}
.bc-i-warning:before {
  content: "\e002";
}
.bc-i-performances-compares:before {
  content: "\e90b";
}
.bc-i-equalizer:before {
  content: "\e01d";
}
.bc-i-tune:before {
  content: "\e42a";
}
.bc-i-spinner:before {
  content: "\e910";
}
.bc-i-calculator:before {
  content: "\e919";
}
.bc-i-classement:before {
  content: "\e91c";
}
.bc-i-enjeux:before {
  content: "\e928";
}
.bc-i-eur:before {
  content: "\e92a";
}
.bc-i-file-text:before {
  content: "\e92b";
}
.bc-i-flag:before {
  content: "\e92c";
}
.bc-i-formule:before {
  content: "\e92e";
}
.bc-i-gains:before {
  content: "\e92f";
}
.bc-i-gearing:before {
  content: "\e930";
}
.bc-i-home:before {
  content: "\e934";
}
.bc-i-internet:before {
  content: "\e936";
}
.bc-i-live:before {
  content: "\e937";
}
.bc-i-minus:before {
  content: "\e949";
}
.bc-i-home-2:before {
  content: "\e88c";
}
.bc-i-time:before {
  content: "\e8b6";
}
.bc-i-wallet:before {
  content: "\e851";
}
.bc-i-label:before {
  content: "\e54f";
}
.bc-i-refresh:before {
  content: "\e863";
}
.bc-i-edit:before {
  content: "\e254";
}
.bc-i-tablet_android:before {
  content: "\e330";
}
.bc-i-power-on-off:before {
  content: "\e8ac";
}
.bc-i-arrow-drop-left:before {
  content: "\e5c7";
}
.bc-i-arrow-drop-down:before {
  content: "\e5c5";
}
.bc-i-arrow_drop_down:before {
  content: "\e5c6";
}
.bc-i-arrow-drop-right:before {
  content: "\e5c8";
}
.bc-i-logout:before {
  content: "\e623";
}
.bc-i-settings:before {
  content: "\e8b8";
}
