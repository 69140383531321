/*-------------------------------- VARIABLES START --------------------------------*/
/*-------------------------------- VARIABLES END --------------------------------*/
/*-------------------------------- CONSTANTS START --------------------------------*/
/*-------------------------------- VARIABLES START --------------------------------*/
/*-------------------------------- VARIABLES END --------------------------------*/
/*-------------------------------- VARIABLES START --------------------------------*/
/*-------------------------------- VARIABLES END --------------------------------*/
/*-------------------------------- VARIABLES START --------------------------------*/
/*-------------------------------- VARIABLES END --------------------------------*/
@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../../assets/fonts/default/Roboto-Bold.woff2?v=463") format("woff2"), url("../../assets/fonts/default/Roboto-Bold.woff?v=463") format("woff"), url("../../assets/fonts/default/Roboto-Bold.ttf?v=463") format("truetype"), url("../../assets/fonts/default/Roboto-Bold.eot?v=463") format("embedded-opentype"), url("../../assets/fonts/default/Roboto-Bold.svg?v=463#Roboto-Bold") format("svg");
  font-display: fallback; }

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: local("Roboto"), local("Roboto-Regular"), url("../../assets/fonts/default/Roboto-Regular.woff2?v=463") format("woff2"), url("../../assets/fonts/default/Roboto-Regular.woff?v=463") format("woff"), url("../../assets/fonts/default/Roboto-Regular.ttf?v=463") format("truetype"), url("../../assets/fonts/default/Roboto-Regular.eot?v=463") format("embedded-opentype"), url("../../assets/fonts/default/Roboto-Regular.svg?v=463#Roboto-Regular") format("svg");
  font-display: fallback; }

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: local("mardoto-regular"), url("../../assets/fonts/arm/mardoto-regular-webfont.woff2?v=463") format("woff2"), url("../../assets/fonts/arm/mardoto-regular-webfont.woff?v=463") format("woff"), url("../../assets/fonts/arm/mardoto-regular-webfont.ttf?v=463") format("truetype");
  unicode-range: U+0530-058F;
  font-display: fallback; }

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local("mardoto-bold"), url("../../assets/fonts/arm/mardoto-bold-webfont.woff2?v=463") format("woff2"), url("../../assets/fonts/arm/mardoto-bold-webfont.woff?v=463") format("woff"), url("../../assets/fonts/arm/mardoto-bold-webfont.ttf?v=463") format("truetype");
  unicode-range: U+0530-058F;
  font-display: fallback; }

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local("IRAN_SemiBold"), url("../../assets/fonts/farsi/IRAN_SemiBold.woff2?v=463") format("woff2"), url("../../assets/fonts/farsi/IRAN_SemiBold.woff?v=463") format("woff"), url("../../assets/fonts/farsi/IRAN_SemiBold.ttf?v=463") format("truetype"), url("../../assets/fonts/farsi/IRAN_SemiBold.eot?v=463");
  unicode-range: U+0600-06FF;
  font-display: fallback; }

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local("IRAN_SemiBold"), url("../../assets/fonts/farsi/IRAN_SemiBold.woff2?v=463") format("woff2"), url("../../assets/fonts/farsi/IRAN_SemiBold.woff?v=463") format("woff"), url("../../assets/fonts/farsi/IRAN_SemiBold.ttf?v=463") format("truetype"), url("../../assets/fonts/farsi/IRAN_SemiBold.eot?v=463");
  unicode-range: U+0600-06FF;
  font-display: fallback; }

/*-------------------------------- VARIABLES START --------------------------------*/
/*-------------------------------- VARIABLES END --------------------------------*/
@font-face {
  font-family: 'BetConstruct-Icons';
  src: url("../../assets/icons/bet_construct_icon_set/fonts/BetConstruct-Icons.eot?v=487");
  src: url("../../assets/icons/bet_construct_icon_set/fonts/BetConstruct-Icons.eot?v=487") format("embedded-opentype"), url("../../assets/icons/bet_construct_icon_set/fonts/BetConstruct-Icons.ttf?v=487") format("truetype"), url("../../assets/icons/bet_construct_icon_set/fonts/BetConstruct-Icons.woff?v=487") format("woff"), url("../../assets/icons/bet_construct_icon_set/fonts/BetConstruct-Icons.svg?v=487#BetConstruct-Icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="bc-i-"], [class*=" bc-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BetConstruct-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bc-i-success:before {
  content: "\e95e"; }

.bc-i-fail:before {
  content: "\e95f"; }

.bc-i-attention:before {
  content: "\e960"; }

.bc-i-my-bets-up:before {
  content: "\e95c"; }

.bc-i-my-bets-down:before {
  content: "\e95d"; }

.bc-i-pmu-couple-place:before {
  content: "\e950"; }

.bc-i-pmu-simple-place:before {
  content: "\e957"; }

.bc-i-pmu-couple-gagnant:before {
  content: "\e907"; }

.bc-i-pmu-couple-ordre:before {
  content: "\e92d"; }

.bc-i-pmu-multi:before {
  content: "\e951"; }

.bc-i-pmu-pick-five:before {
  content: "\e952"; }

.bc-i-pmu-quante-plus:before {
  content: "\e953"; }

.bc-i-pmu-quarte-plus:before {
  content: "\e954"; }

.bc-i-pmu-quinte-plus:before {
  content: "\e955"; }

.bc-i-pmu-simple-gagnant:before {
  content: "\e956"; }

.bc-i-pmu-tierce:before {
  content: "\e958"; }

.bc-i-pmu-trio-ordre:before {
  content: "\e959"; }

.bc-i-pmu-trio:before {
  content: "\e95a"; }

.bc-i-pmu-simple-placed-e:before {
  content: "\e908"; }

.bc-i-pmu-simple-gagnant-e:before {
  content: "\e909"; }

.bc-i-pmu-quante-plus-e:before {
  content: "\e929"; }

.bc-i-pmu-multi-e:before {
  content: "\e906"; }

.bc-i-pmu-trio-e:before {
  content: "\e905"; }

.bc-i-pmu-quarte-plus-e:before {
  content: "\e904"; }

.bc-i-pmu-tierce-e:before {
  content: "\e903"; }

.bc-i-pmu-couple-placed-e:before {
  content: "\e902"; }

.bc-i-pmu-couple-gagnant-e:before {
  content: "\e901"; }

.bc-i-login:before {
  content: "\e624"; }

.bc-i-pmu-couple-ordre-e:before {
  content: "\e900"; }

.bc-i-handshake:before {
  content: "\e95b"; }

.bc-i-info-2:before {
  content: "\e633"; }

.bc-i-add:before {
  content: "\e145"; }

.bc-i-remove:before {
  content: "\e15b"; }

.bc-i-burger:before {
  content: "\e5d2"; }

.bc-i-volume-down:before {
  content: "\e04d"; }

.bc-i-volume-mute:before {
  content: "\e04e"; }

.bc-i-volume-off:before {
  content: "\e04f"; }

.bc-i-volume-up:before {
  content: "\e050"; }

.bc-i-search-header-new1:before {
  content: "\e6d5"; }

.bc-i-thumb-down:before {
  content: "\e8db"; }

.bc-i-thumb-up:before {
  content: "\e8dc"; }

.bc-i-search:before {
  content: "\e6d6"; }

.bc-i-more-horiz:before {
  content: "\e5d3"; }

.bc-i-more-vert:before {
  content: "\e5d4"; }

.bc-i-zmw:before {
  content: "\e9c4"; }

.bc-i-zar:before {
  content: "\e9c5"; }

.bc-i-xof:before {
  content: "\e9c6"; }

.bc-i-vnd:before {
  content: "\e9c7"; }

.bc-i-vef:before {
  content: "\e9c8"; }

.bc-i-uzs:before {
  content: "\e9c9"; }

.bc-i-uyu:before {
  content: "\e9ca"; }

.bc-i-ugx:before {
  content: "\e9cb"; }

.bc-i-uah:before {
  content: "\e9cc"; }

.bc-i-tzs:before {
  content: "\e9cd"; }

.bc-i-ttd:before {
  content: "\e9ce"; }

.bc-i-tnd:before {
  content: "\e9cf"; }

.bc-i-tjs:before {
  content: "\e9d0"; }

.bc-i-thb:before {
  content: "\e9d1"; }

.bc-i-syp:before {
  content: "\e9d2"; }

.bc-i-sll:before {
  content: "\e9d3"; }

.bc-i-sgd:before {
  content: "\e9d4"; }

.bc-i-sar:before {
  content: "\e9d5"; }

.bc-i-rsd:before {
  content: "\e9d6"; }

.bc-i-ron:before {
  content: "\e9d7"; }

.bc-i-qar:before {
  content: "\e9d8"; }

.bc-i-pyg:before {
  content: "\e9d9"; }

.bc-i-pln:before {
  content: "\e9da"; }

.bc-i-pen:before {
  content: "\e9db"; }

.bc-i-pab:before {
  content: "\e9dc"; }

.bc-i-ngn:before {
  content: "\e9dd"; }

.bc-i-mzn:before {
  content: "\e9de"; }

.bc-i-myr:before {
  content: "\e9df"; }

.bc-i-mxn:before {
  content: "\e9e0"; }

.bc-i-mkd:before {
  content: "\e9e1"; }

.bc-i-mdl:before {
  content: "\e9e2"; }

.bc-i-mad:before {
  content: "\e9e3"; }

.bc-i-lkr:before {
  content: "\e9e4"; }

.bc-i-lbp:before {
  content: "\e9e5"; }

.bc-i-kzt:before {
  content: "\e9e6"; }

.bc-i-kgs:before {
  content: "\e9e7"; }

.bc-i-kes:before {
  content: "\e9e8"; }

.bc-i-jod:before {
  content: "\e9e9"; }

.bc-i-jmd:before {
  content: "\e9ea"; }

.bc-i-irr:before {
  content: "\e9eb"; }

.bc-i-iqd:before {
  content: "\e9ec"; }

.bc-i-inr:before {
  content: "\e9ed"; }

.bc-i-ils:before {
  content: "\e9ee"; }

.bc-i-idr:before {
  content: "\e9ef"; }

.bc-i-huf:before {
  content: "\e9f0"; }

.bc-i-htg:before {
  content: "\e9f1"; }

.bc-i-hrk:before {
  content: "\e9f2"; }

.bc-i-hnl:before {
  content: "\e9f3"; }

.bc-i-hkd:before {
  content: "\e9f4"; }

.bc-i-gtq:before {
  content: "\e9f5"; }

.bc-i-gmd:before {
  content: "\e9f6"; }

.bc-i-gel:before {
  content: "\e9f7"; }

.bc-i-dop:before {
  content: "\e9f8"; }

.bc-i-czk:before {
  content: "\e9f9"; }

.bc-i-crc:before {
  content: "\e9fa"; }

.bc-i-cop:before {
  content: "\e9fb"; }

.bc-i-clp:before {
  content: "\e9fc"; }

.bc-i-cad:before {
  content: "\e9fd"; }

.bc-i-byr:before {
  content: "\e9fe"; }

.bc-i-bsd:before {
  content: "\e9ff"; }

.bc-i-brl:before {
  content: "\ea00"; }

.bc-i-bob:before {
  content: "\ea01"; }

.bc-i-bmd:before {
  content: "\ea02"; }

.bc-i-bgn:before {
  content: "\ea03"; }

.bc-i-bdt:before {
  content: "\ea04"; }

.bc-i-bam:before {
  content: "\ea05"; }

.bc-i-azn:before {
  content: "\ea06"; }

.bc-i-aoa:before {
  content: "\ea08"; }

.bc-i-aed:before {
  content: "\ea09"; }

.bc-i-amd:before {
  content: "\ea0a"; }

.bc-i-pound:before {
  content: "\e9b7"; }

.bc-i-australian-dollar:before {
  content: "\e9b8"; }

.bc-i-swiss-franc:before {
  content: "\e9b9"; }

.bc-i-euro:before {
  content: "\e9ba"; }

.bc-i-yen:before {
  content: "\e9bb"; }

.bc-i-lira:before {
  content: "\e9bc"; }

.bc-i-south-korean-won:before {
  content: "\e9bd"; }

.bc-i-swedish-krona:before {
  content: "\e9be"; }

.bc-i-peso:before {
  content: "\e9bf"; }

.bc-i-cent:before {
  content: "\e9c0"; }

.bc-i-bitcoin:before {
  content: "\e9b4"; }

.bc-i-ruble:before {
  content: "\e9b5"; }

.bc-i-dollar:before {
  content: "\e9b6"; }

.bc-i-discipline-obstacle:before {
  content: "\e920"; }

.bc-i-discipline-plat:before {
  content: "\e921"; }

.bc-i-discipline-trot:before {
  content: "\e922"; }

.bc-i-cheval:before {
  content: "\e91b"; }

.bc-i-discipline-trot-attele:before {
  content: "\e923"; }

.bc-i-discipline-trot-monte:before {
  content: "\e924"; }

.bc-i-picto-pe:before {
  content: "\e90d"; }

.bc-i-oeillere-austral:before {
  content: "\e90a"; }

.bc-i-programme-details:before {
  content: "\e90f"; }

.bc-i-meteo-p1:before {
  content: "\e939"; }

.bc-i-meteo-p4:before {
  content: "\e93a"; }

.bc-i-meteo-p8:before {
  content: "\e93b"; }

.bc-i-meteo-p8c:before {
  content: "\e93c"; }

.bc-i-meteo-p10:before {
  content: "\e93d"; }

.bc-i-meteo-p11:before {
  content: "\e93e"; }

.bc-i-meteo-p13:before {
  content: "\e940"; }

.bc-i-meteo-p13a:before {
  content: "\e941"; }

.bc-i-meteo-p14:before {
  content: "\e942"; }

.bc-i-meteo-p18:before {
  content: "\e946"; }

.bc-i-meteo-p18a:before {
  content: "\e947"; }

.bc-i-meteo-p15:before {
  content: "\e943"; }

.bc-i-meteo-p16:before {
  content: "\e944"; }

.bc-i-meteo-p17:before {
  content: "\e945"; }

.bc-i-meteo-p12:before {
  content: "\e93f"; }

.bc-i-spot:before {
  content: "\e912"; }

.bc-i-course-annulee:before {
  content: "\e91d"; }

.bc-i-course-en-cours:before {
  content: "\e91e"; }

.bc-i-depart:before {
  content: "\e91f"; }

.bc-i-distance:before {
  content: "\e925"; }

.bc-i-dtlo:before {
  content: "\e926"; }

.bc-i-encours:before {
  content: "\e927"; }

.bc-i-heart:before {
  content: "\e932"; }

.bc-i-help-11:before {
  content: "\e933"; }

.bc-i-mail-forward:before {
  content: "\e938"; }

.bc-i-min3:before {
  content: "\e948"; }

.bc-i-move:before {
  content: "\e94a"; }

.bc-i-next-course:before {
  content: "\e94b"; }

.bc-i-next-programme:before {
  content: "\e94c"; }

.bc-i-notdef:before {
  content: "\e94d"; }

.bc-i-user:before {
  content: "\e915"; }

.bc-i-user-2:before {
  content: "\e916"; }

.bc-i-plus-joues:before {
  content: "\e90e"; }

.bc-i-sponsorship:before {
  content: "\e911"; }

.bc-i-personnes:before {
  content: "\e90c"; }

.bc-i-trash:before {
  content: "\e913"; }

.bc-i-trash-filled:before {
  content: "\e914"; }

.bc-i-delete-1:before {
  content: "\e872"; }

.bc-i-delete-2:before {
  content: "\e94f"; }

.bc-i-arrow-left-2:before {
  content: "\e317"; }

.bc-i-arrow-right-2:before {
  content: "\e318"; }

.bc-i-arrow-top-2:before {
  content: "\e319"; }

.bc-i-arrow-bottom-2:before {
  content: "\e31a"; }

.bc-i-arrow-left:before {
  content: "\e5d0"; }

.bc-i-arrow-right:before {
  content: "\e5d1"; }

.bc-i-arrow-top:before {
  content: "\e5ce"; }

.bc-i-arrow-bottom:before {
  content: "\e5cf"; }

.bc-i-cadeau:before {
  content: "\e918"; }

.bc-i-gift:before {
  content: "\e931"; }

.bc-i-gift1:before {
  content: "\e8b2"; }

.bc-i-calendar-2:before {
  content: "\e94e"; }

.bc-i-calendar-3:before {
  content: "\e91a"; }

.bc-i-calendar-1:before {
  content: "\e616"; }

.bc-i-favorite-2:before {
  content: "\e87d"; }

.bc-i-favorite-unselected-2:before {
  content: "\e87e"; }

.bc-i-star-selected:before {
  content: "\e838"; }

.bc-i-star-unselected:before {
  content: "\e83a"; }

.bc-i-location:before {
  content: "\e8b4"; }

.bc-i-location-off:before {
  content: "\e0c7"; }

.bc-i-mic:before {
  content: "\e029"; }

.bc-i-mic-off:before {
  content: "\e02b"; }

.bc-i-lock-1:before {
  content: "\e897"; }

.bc-i-lock-2:before {
  content: "\e899"; }

.bc-i-lock-unclocked-2:before {
  content: "\e898"; }

.bc-i-cloud-1:before {
  content: "\e2c3"; }

.bc-i-cloud-2:before {
  content: "\e42e"; }

.bc-i-play-arrow:before {
  content: "\e037"; }

.bc-i-pause:before {
  content: "\e034"; }

.bc-i-lens:before {
  content: "\e3fa"; }

.bc-i-play-circle:before {
  content: "\e038"; }

.bc-i-cancel:before {
  content: "\e5c9"; }

.bc-i-close-remove:before {
  content: "\e5cd"; }

.bc-i-checkbox-checked:before {
  content: "\e834"; }

.bc-i-checkbox-unchecked:before {
  content: "\e835"; }

.bc-i-checked:before {
  content: "\e5ca"; }

.bc-i-info-1:before {
  content: "\e935"; }

.bc-i-help-1:before {
  content: "\e8fe"; }

.bc-i-help-2:before {
  content: "\e888"; }

.bc-i-warning-outlines:before {
  content: "\e917"; }

.bc-i-warning:before {
  content: "\e002"; }

.bc-i-performances-compares:before {
  content: "\e90b"; }

.bc-i-equalizer:before {
  content: "\e01d"; }

.bc-i-tune:before {
  content: "\e42a"; }

.bc-i-spinner:before {
  content: "\e910"; }

.bc-i-calculator:before {
  content: "\e919"; }

.bc-i-classement:before {
  content: "\e91c"; }

.bc-i-enjeux:before {
  content: "\e928"; }

.bc-i-eur:before {
  content: "\e92a"; }

.bc-i-file-text:before {
  content: "\e92b"; }

.bc-i-flag:before {
  content: "\e92c"; }

.bc-i-formule:before {
  content: "\e92e"; }

.bc-i-gains:before {
  content: "\e92f"; }

.bc-i-gearing:before {
  content: "\e930"; }

.bc-i-home:before {
  content: "\e934"; }

.bc-i-internet:before {
  content: "\e936"; }

.bc-i-live:before {
  content: "\e937"; }

.bc-i-minus:before {
  content: "\e949"; }

.bc-i-home-2:before {
  content: "\e88c"; }

.bc-i-time:before {
  content: "\e8b6"; }

.bc-i-wallet:before {
  content: "\e851"; }

.bc-i-label:before {
  content: "\e54f"; }

.bc-i-refresh:before {
  content: "\e863"; }

.bc-i-edit:before {
  content: "\e254"; }

.bc-i-tablet_android:before {
  content: "\e330"; }

.bc-i-power-on-off:before {
  content: "\e8ac"; }

.bc-i-arrow-drop-left:before {
  content: "\e5c7"; }

.bc-i-arrow-drop-down:before {
  content: "\e5c5"; }

.bc-i-arrow_drop_down:before {
  content: "\e5c6"; }

.bc-i-arrow-drop-right:before {
  content: "\e5c8"; }

.bc-i-logout:before {
  content: "\e623"; }

.bc-i-settings:before {
  content: "\e8b8"; }

html {
  height: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  background: #1a1e26;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: c(skin-10) c(skin-1);
  scrollbar-width: thin; }
  html[dir=ltr] {
    direction: ltr; }
  html[dir=rtl] {
    direction: rtl; }
  html[lang="ka"] * {
    text-transform: none !important; }

body {
  height: 100%;
  width: 100%;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 1; }

::-webkit-scrollbar {
  width: 4px;
  background: c(skin-1);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: c(skin-10); }

::-webkit-scrollbar-track {
  border-radius: 10px; }

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  #root > .loader-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
  #root ~ div > .grecaptcha-badge {
    display: none; }

.fix-scroll {
  overflow: hidden; }

.loader-wrapper {
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: block;
  vertical-align: top;
  margin: auto;
  position: relative;
  transform: translate3d(0, 0, 0);
  animation: 400ms transparent-v ease-in-out; }

@keyframes transparent-v {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .loader-wrapper:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 4px solid #a61f67;
    border-right: 4px solid transparent;
    animation: spinner 1200ms linear infinite; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }
  .loader-wrapper.small-progress {
    width: 20px;
    height: 20px; }
    .loader-wrapper.small-progress:before {
      border-right-width: 2px;
      border-top-width: 2px; }
      [dir=rtl] .loader-wrapper.small-progress:before {
        border-left-width: 2px; }

body [class^="MuiList-padding-"],
body [class*="MuiList-padding-"] {
  padding: 0; }

body [class^="MuiPaper-rounded-"],
body [class*="MuiPaper-rounded-"] {
  border-radius: 2px;
  background: var(--skin-5); }

body [class^="MuiMenuItem-root-"],
body [class*="MuiMenuItem-root-"] {
  height: 27px;
  padding: 0 12px;
  border-bottom: 1px solid var(--skin-9);
  font-size: 12px;
  font-family: inherit;
  color: var(--text-2);
  line-height: 26px;
  transition-property: color, background;
  transition-duration: 0.23s; }
  body [class^="MuiMenuItem-root-"]:hover,
  body [class*="MuiMenuItem-root-"]:hover {
    color: var(--text-1);
    background: var(--skin-6); }
  body [class^="MuiMenuItem-root-"] > span,
  body [class*="MuiMenuItem-root-"] > span {
    display: none; }
  body [class^="MuiMenuItem-root-"]:last-child,
  body [class*="MuiMenuItem-root-"]:last-child {
    border-bottom: none; }

body [class^="MuiListItem-selected-"],
body [class*="MuiListItem-selected-"] {
  background-color: #1a7051 !important;
  color: var(--text-1); }

body [class^="MuiPickersToolbar-toolbar-"],
body [class*="MuiPickersToolbar-toolbar-"] {
  background-color: #a61f67; }

body [class^="MuiPickersToolbarButton-toolbarBtn-"],
body [class*="MuiPickersToolbarButton-toolbarBtn-"] {
  color: var(--text-2) !important;
  transition: color 0.23s; }
  body [class^="MuiPickersToolbarButton-toolbarBtn-"]:hover,
  body [class*="MuiPickersToolbarButton-toolbarBtn-"]:hover {
    color: var(--text-1); }

body [class^="MuiPickersToolbarButton-toolbarBtnSelected-"],
body [class*="MuiPickersToolbarButton-toolbarBtnSelected-"] {
  color: var(--text-1) !important; }

body [class^="MuiPickersModal-dialogRoot-"],
body [class*="MuiPickersModal-dialogRoot-"],
body [class^="MuiPickersCalendarHeader-iconButton-"],
body [class*="MuiPickersCalendarHeader-iconButton-"],
body [class^="MuiPickersCalendarHeader-iconButton-"] > *,
body [class*="MuiPickersCalendarHeader-iconButton-"] > * {
  background-color: var(--skin-1); }

[dir=rtl] body [class^="MuiPickersCalendarHeader-iconButton-"], [dir=rtl]
body [class*="MuiPickersCalendarHeader-iconButton-"] {
  transform: rotateY(180deg); }

body [class^="MuiPickersCalendarHeader-iconButton-"] > * > *,
body [class*="MuiPickersCalendarHeader-iconButton-"] > * > * {
  transition: color 0.23s;
  color: var(--text-1); }
  body [class^="MuiPickersCalendarHeader-iconButton-"] > * > *:hover,
  body [class*="MuiPickersCalendarHeader-iconButton-"] > * > *:hover {
    color: #a61f67; }

body [class^="MuiTypography-body"],
body [class*="MuiTypography-body"] {
  color: var(--text-9); }

body [class^="MuiPickersCalendarHeader-dayLabel-"],
body [class*="MuiPickersCalendarHeader-dayLabel-"] {
  color: rgba(var(--text-9), 0.8); }

body [class^="MuiPickersDay-day-"],
body [class*="MuiPickersDay-day-"] {
  color: var(--text-9); }

body [class^="MuiPickersDay-current-"],
body [class*="MuiPickersDay-current-"] {
  color: #a61f67; }

body [class^="MuiPickersDay-selected-"],
body [class*="MuiPickersDay-selected-"] {
  color: var(--text-1);
  background-color: #a61f67; }
  body [class^="MuiPickersDay-selected-"]:hover,
  body [class*="MuiPickersDay-selected-"]:hover {
    background-color: #a61f67; }

body [class^="MuiDialogActions-root-"],
body [class*="MuiDialogActions-root-"] {
  box-shadow: 0 1px 0 0 rgba(var(--skin-4), 0.2) inset;
  margin: 0px 12px 12px;
  padding-top: 13px; }

body [class^="MuiButton-root-"],
body [class*="MuiButton-root-"] {
  min-width: 100px;
  min-height: 30px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 1px solid #1a7051;
  font-size: 13px;
  transition-property: background, border;
  transition-duration: 0.23s;
  cursor: pointer; }
  body [class^="MuiButton-root-"]:nth-child(1),
  body [class*="MuiButton-root-"]:nth-child(1) {
    color: var(--text-1); }
    [dir=ltr] body [class^="MuiButton-root-"]:nth-child(1), [dir=ltr]
    body [class*="MuiButton-root-"]:nth-child(1) {
      border-right: 0; }
    [dir=rtl] body [class^="MuiButton-root-"]:nth-child(1), [dir=rtl]
    body [class*="MuiButton-root-"]:nth-child(1) {
      border-left: 0; }
    body [class^="MuiButton-root-"]:nth-child(1):hover,
    body [class*="MuiButton-root-"]:nth-child(1):hover {
      background: rgba(var(--skin-2), 0.4);
      border-color: #24996f; }
  body [class^="MuiButton-root-"]:nth-child(2),
  body [class*="MuiButton-root-"]:nth-child(2) {
    background: #1a7051;
    color: var(--text-1); }
    body [class^="MuiButton-root-"]:nth-child(2):hover,
    body [class*="MuiButton-root-"]:nth-child(2):hover {
      background: #24996f; }

body [class^="MuiTypography-subtitle1-"],
body [class*="MuiTypography-subtitle1-"] {
  color: var(--text-7);
  transition: color 0.23s; }
  body [class^="MuiTypography-subtitle1-"]:hover,
  body [class*="MuiTypography-subtitle1-"]:hover {
    color: #a61f67; }

body [class^="MuiTypography-colorPrimary-"],
body [class*="MuiTypography-colorPrimary-"] {
  color: #a61f67; }

body [class^="MuiInputBase-root-"],
body [class*="MuiInputBase-root-"] {
  width: 100%; }

body [class^="MuiInput-underline-"]:before, body [class^="MuiInput-underline-"]:after,
body [class*="MuiInput-underline-"]:before,
body [class*="MuiInput-underline-"]:after {
  display: none; }

body [class^="MuiInputBase-input-"],
body [class*="MuiInputBase-input-"] {
  font-size: 11px;
  color: var(--text-4); }
  [dir=ltr] body [class^="MuiInputBase-input-"], [dir=ltr]
  body [class*="MuiInputBase-input-"] {
    padding: 6px 8px 7px 8px; }
  [dir=rtl] body [class^="MuiInputBase-input-"], [dir=rtl]
  body [class*="MuiInputBase-input-"] {
    padding: 6px 8px 7px 32px; }

body [class^="MuiSvgIcon-root-"],
body [class*="MuiSvgIcon-root-"] {
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 20px;
  color: var(--text-12); }
  [dir=ltr] body [class^="MuiSvgIcon-root-"], [dir=ltr]
  body [class*="MuiSvgIcon-root-"] {
    right: 4px;
    left: initial; }
  [dir=rtl] body [class^="MuiSvgIcon-root-"], [dir=rtl]
  body [class*="MuiSvgIcon-root-"] {
    left: 4px;
    right: initial; }

body label + [class^="MuiInput-formControl-"],
body label + [class*="MuiInput-formControl-"] {
  margin-top: 0; }

body [class^="MuiIconButton-root-"],
body [class*="MuiIconButton-root-"] {
  padding: 3px; }

body [class^="MuiIcon-root-"],
body [class*="MuiIcon-root-"] {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--text-4);
  transition: color 0.23s; }
  [dir=ltr] body [class^="MuiIcon-root-"], [dir=ltr]
  body [class*="MuiIcon-root-"] {
    margin-right: 2px; }
  [dir=rtl] body [class^="MuiIcon-root-"], [dir=rtl]
  body [class*="MuiIcon-root-"] {
    margin-left: 2px; }
  body [class^="MuiIcon-root-"]:hover,
  body [class*="MuiIcon-root-"]:hover {
    color: #a61f67; }

body [class^="MuiInputAdornment-root-"],
body [class*="MuiInputAdornment-root-"] {
  height: initial; }
  [dir=ltr] body [class^="MuiInputAdornment-root-"], [dir=ltr]
  body [class*="MuiInputAdornment-root-"] {
    margin-right: 2px; }
  [dir=rtl] body [class^="MuiInputAdornment-root-"], [dir=rtl]
  body [class*="MuiInputAdornment-root-"] {
    margin-left: 2px; }

body .pmu-p-o-h-c-i-c-i [class^="MuiFormLabel-filled-"],
body .pmu-p-o-h-c-i-c-i [class*="MuiFormLabel-filled-"] {
  display: none; }

.md-restyle {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  position: relative; }
  .md-restyle.select {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset; }
    .md-restyle.select > div {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100%; }
      .md-restyle.select > div:before, .md-restyle.select > div:after {
        content: none; }
    .md-restyle.select > div > div {
      display: inline-block;
      vertical-align: top;
      height: 100%; }
      .md-restyle.select > div > div:after {
        content: '\e5cf';
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        font-family: 'BetConstruct-Icons' !important;
        font-size: 16px;
        color: var(--text-10);
        line-height: 1;
        transition: color 0.23s;
        pointer-events: none; }
        [dir=ltr] .md-restyle.select > div > div:after {
          right: 5px; }
        [dir=rtl] .md-restyle.select > div > div:after {
          left: 5px; }
      .md-restyle.select > div > div:hover:after {
        color: var(--text-1); }
      .md-restyle.select > div > div:hover > div {
        color: var(--text-1); }
    .md-restyle.select > div > div > div {
      height: 100%;
      box-sizing: border-box;
      font-size: 12px;
      color: var(--text-2);
      transition: color 0.23s;
      text-transform: capitalize; }
      [dir=ltr] .md-restyle.select > div > div > div {
        padding: 0 24px 0 4px; }
      [dir=rtl] .md-restyle.select > div > div > div {
        padding: 4px 0 0 24px; }
    .md-restyle.select > div > div > input,
    .md-restyle.select > div > div > svg {
      display: none; }
  .md-restyle.date-picker > div {
    width: 100%;
    height: 100%; }
  .md-restyle.date-picker > div > div {
    width: 100%;
    height: 100%;
    position: relative; }
    [dir=ltr] .md-restyle.date-picker > div > div {
      padding-right: 30px; }
    [dir=rtl] .md-restyle.date-picker > div > div {
      padding-left: 30px; }
    .md-restyle.date-picker > div > div:before, .md-restyle.date-picker > div > div:after {
      content: none; }
  .md-restyle.date-picker > div > div > div {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 100%;
    max-height: 100%;
    margin: 0;
    position: absolute;
    top: 0; }
    [dir=ltr] .md-restyle.date-picker > div > div > div {
      right: 0; }
    [dir=rtl] .md-restyle.date-picker > div > div > div {
      left: 0; }
  .md-restyle.date-picker input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0; }
  .md-restyle.date-picker button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    line-height: 30px;
    border-radius: initial;
    transition: background 0.23s; }
  .md-restyle.date-picker button > span:nth-child(1) {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%; }
    .md-restyle.date-picker button > span:nth-child(1) > span {
      display: none; }
    .md-restyle.date-picker button > span:nth-child(1):before {
      content: '\e94e';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-family: 'BetConstruct-Icons' !important;
      font-size: 20px;
      color: var(--text-2);
      line-height: 1;
      transition: color 0.23s;
      cursor: pointer; }
  .md-restyle.date-picker button > span:nth-child(2) {
    display: none !important; }

.betconstruct-guidelines-wrapper {
  height: 100%;
  background: #000; }

.betconstruct-guidelines {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0; }
  .betconstruct-guidelines .content-head {
    width: 100%;
    height: 48px; }
  .betconstruct-guidelines .content-head-title {
    font-weight: bold;
    font-size: 24px;
    color: var(--text-1);
    text-align: center;
    text-transform: uppercase; }
  .betconstruct-guidelines .box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px; }
  .betconstruct-guidelines.ext-1 .box.old-1 {
    background: #121623; }
  .betconstruct-guidelines.ext-1 .box.old-2 {
    background: #171c2c; }
  .betconstruct-guidelines.ext-1 .box.old-3 {
    background: #1e2639; }
  .betconstruct-guidelines.ext-1 .box.old-4 {
    background: #242e45; }
  .betconstruct-guidelines.ext-1 .box.old-5 {
    background: #212532; }
  .betconstruct-guidelines.ext-1 .box.old-6 {
    background: #2e323e; }
  .betconstruct-guidelines.ext-1 .box.old-7 {
    background: #3f4553; }
  .betconstruct-guidelines.ext-1 .box.old-8 {
    background: #505767; }
  .betconstruct-guidelines.ext-1 .box.old-9 {
    background: #7b8191; }
  .betconstruct-guidelines.ext-1 .box.old-10 {
    background: #c2c5cd; }
  .betconstruct-guidelines.ext-1 .box.old-11 {
    background: #f3f3f5; }
  .betconstruct-guidelines.ext-1 .box.old-12 {
    background: var(--skin-12); }
  .betconstruct-guidelines.ext-1 .box.b-1 {
    background: var(--skin-1); }
  .betconstruct-guidelines.ext-1 .box.b-2 {
    background: var(--skin-2); }
  .betconstruct-guidelines.ext-1 .box.b-3 {
    background: var(--skin-4); }
  .betconstruct-guidelines.ext-1 .box.b-4 {
    background: var(--skin-4); }
  .betconstruct-guidelines.ext-1 .box.b-5 {
    background: var(--skin-5); }
  .betconstruct-guidelines.ext-1 .box.b-6 {
    background: var(--skin-6); }
  .betconstruct-guidelines.ext-1 .box.b-7 {
    background: var(--skin-7); }
  .betconstruct-guidelines.ext-1 .box.b-8 {
    background: var(--skin-8); }
  .betconstruct-guidelines.ext-1 .box.b-9 {
    background: var(--skin-9); }
  .betconstruct-guidelines.ext-1 .box.b-10 {
    background: var(--skin-10); }
  .betconstruct-guidelines.ext-1 .box.b-11 {
    background: var(--skin-11); }
  .betconstruct-guidelines.ext-1 .box.b-12 {
    background: var(--skin-12); }
  .betconstruct-guidelines.ext-2 .box.b-1 {
    background: var(--skin-1); }
  .betconstruct-guidelines.ext-2 .box.b-2 {
    background: var(--skin-2); }
  .betconstruct-guidelines.ext-2 .box.b-3 {
    background: var(--skin-3); }
  .betconstruct-guidelines.ext-2 .box.b-4 {
    background: var(--skin-4); }
  .betconstruct-guidelines.ext-2 .box.b-5 {
    background: var(--skin-5); }
  .betconstruct-guidelines.ext-2 .box.b-6 {
    background: var(--skin-7); }
  .betconstruct-guidelines.ext-2 .box.b-7 {
    background: var(--skin-7); }
  .betconstruct-guidelines.ext-2 .box.b-8 {
    background: var(--skin-8); }
  .betconstruct-guidelines.ext-2 .box.b-9 {
    background: var(--skin-9); }
  .betconstruct-guidelines.ext-2 .box.b-10 {
    background: var(--skin-10); }
  .betconstruct-guidelines.ext-2 .box.b-11 {
    background: var(--skin-11); }
  .betconstruct-guidelines.ext-2 .box.b-12 {
    background: var(--skin-12); }
