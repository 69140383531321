@import "skin";

// ---------- Popups Start ----------
.pmu-popup {
  position: relative;
  background: c(skin-10);

  &.place-bet {
    max-width: 502px;
  }

  &.confirmation-popup {
    width: 502px;
  }

  &.status {
    max-width: 502px;
  }

  &.notification{
    width: 280px;

    .pmu-popup-b{
      text-align: center;
    }

    .pmu-popup-actions{
      padding-top: 24px;
    }
  }
}

.pmu-popup-h {
  height: 46px;
  -webkit-position: sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background: c(skin-7);

  [dir=ltr] & {
    padding: 0 42px 0 16px;
  }

  [dir=rtl] & {
    padding: 0 16px 0 42px;
  }

  > .icon {
    display: inline-block;
    vertical-align: top;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    margin: auto 0;
    font-size: 18px;
    color: c(text-2);
    transition: color $transition-length;
    cursor: pointer;

    [dir=ltr] & {
      right: 16px;
    }
    [dir=rtl] & {
      left: 16px;
    }

    &:hover {
      color: $close-remove-color;
    }
  }
}

.pmu-popup-h {
  height: 46px;
  position: relative;
  background: c(skin-7);
  line-height: 46px;

  [dir=ltr] & {
    padding: 0 40px 0 16px;
  }
  [dir=rtl] & {
    padding: 0 16px 0 40px;
  }
}

.pmu-popup-h-l {
  display: inline-block;
  margin-top: 12px;

  [dir=ltr] & {
    float: left;
    margin-right: 12px;
  }
  [dir=rtl] & {
    float: right;
    margin-left: 12px;
  }
}

.pmu-popup-h-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  line-height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-popup-h-c-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 16px;
  color: c(text-2);
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 16px;
  }
  [dir=rtl] & {
    left: 16px;
  }

  &:hover {
    color: $close-remove-color;
  }
}

.pmu-popup-b {
  padding: 16px 12px 30px 12px;
}

//----------------------------------------------------------------
.pmu-popup-b-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 26px;
}

.pmu-popup-b-t-c {
  display: table-cell;
  font-weight: normal;
  font-size: 10px;
  color: c(text-2);
  line-height: 26px;

  &:nth-child(1) {
    [dir=ltr] & {
      text-align: left;
    }
    [dir=rtl] & {
      text-align: right;
    }
  }
  &:nth-child(2) {
    text-align: center;
  }
  &:nth-child(3) {
    [dir=ltr] & {
      text-align: right;
    }
    [dir=rtl] & {
      text-align: left;
    }
  }
}

.pmu-popup-t {
  height: 30px;
  position: relative;
  background: c(skin-7);
  padding: 0 24px;

  > .pmu-popup-hr {
    display: block;
    height: 30px;
    font-weight: normal;
    font-size: 12px;
    color: c(text-2);
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;
  }

  > .icon {
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 18px;
    color: c(text-10);
    transition: color $transition-length;
    cursor: pointer;

    &.left {
      [dir=ltr] & {
        left: 2px;
      }
      [dir=rtl] & {
        transform: rotateY(180deg);
        right: 2px;
      }
    }
    &.right {
      [dir=ltr] & {
        right: 2px;
      }
      [dir=rtl] & {
        transform: rotateY(180deg);
        left: 2px;
      }
    }

    &:hover {
      color: c(text-1);
    }
  }
}

.pmu-popup-d-w {

}

.pmu-popup-d-t {
  height: 30px;
  font-weight: normal;
  font-size: 10px;
  color: c(text-3);
  line-height: 30px;
}

.pmu-popup-d-ct {
  display: inline-block;
  width: 100%;
}

.pmu-popup-d-i {
  display: inline-block;
  height: 22px;
  width: 50%;

  [dir=ltr] & {
    float: left;
  }
  [dir=rtl] & {
    float: right;
  }

  &:nth-child(odd) {
    [dir=ltr] & {
      padding-right: 1px;
    }
    [dir=rtl] & {
      padding-left: 1px;
    }
  }
  &:nth-child(even) {
    [dir=ltr] & {
      padding-left: 1px;
    }
    [dir=rtl] & {
      padding-right: 1px;
    }
  }

  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    > .pmu-popup-d-i-t {
      background: c(skin-7);
    }
  }
}

.pmu-popup-d-i-t {
  display: block;
  padding: 0 8px;
  font-weight: normal;
  font-size: 10px;
  color: c(text-3);
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-popup-i {
  min-height: 49px;
  border-bottom: 1px solid c(skin-5);

  &:hover,
  &.active {
    > .pmu-popup-i-h {
      > .pmu-popup-i-h-t {
        color: c(text-1);
      }

      > .icon {
        color: c(text-1);
      }
    }
  }

  &.active {
    > .pmu-popup-i-h {
      > .icon {
        transform: rotateX(180deg);
      }
    }
  }
}

.pmu-popup-i-h {
  position: relative;
  cursor: pointer;

  [dir=ltr] & {
    padding: 0 42px 0 16px;
  }

  [dir=rtl] & {
    padding: 0 16px 0 42px;
  }

  > .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 20px;
    color: c(text-10);
    transition-property: transform, color;
    transition-duration: $transition-length;

    [dir=ltr] & {
      right: 14px;
    }
    [dir=rtl] & {
      left: 14px;
    }
  }
}

.pmu-popup-i-h-t {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-3);
  line-height: 49px;
  transition: color $transition-length;
}

.pmu-popup-i-b {
  overflow: hidden;
}

.pmu-popup-actions {
  text-align: center;

  &.place-bet {
    padding-top: 24px;
  }

  &.confirmation {
    padding-top: 42px;
  }
}

.pmu-popup-a {
  display: inline-block;
  min-width: 120px;
  height: 24px;
  padding: 0 12px;
  border-radius: 2px;
  border: 1px solid $brand-color-1-bg;
  outline: none;
  background: transparent;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  text-align: center;
  line-height: 22px;
  transition-property: background, color;
  transition-duration: $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    margin-right: 7px;
  }
  [dir=rtl] & {
    margin-left: 7px;
  }

  &:last-child {
    margin: 0;
  }

  &:nth-child(2) {
    background: $brand-color-1-bg;
  }

  &:hover {
    background: $brand-color-1-bg-hover-active;
    color: c(text-1);
  }
}

.pmu-p-b-p-t {
  font-weight: normal;
  text-align: center;

  &.t-1 {
    font-size: 12px;
    color: c(text-1);
  }
  &.t-2 {
    padding-top: 8px;
    font-size: 10px;
    color: c(text-3);
  }
}

.pmu-p-b-p-bt-w {
  height: 36px;
  margin-top: 16px;
  padding: 0 16px;
  background: rgba(c(skin-1), 0.6);
}

.pmu-p-b-p-bt-t {
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 36px;

  &:first-child {
    [dir=ltr] & {
      float: left;
    }
    [dir=rtl] & {
      float: right;
    }
  }
  &:last-child {
    [dir=ltr] & {
      float: right;
    }
    [dir=rtl] & {
      float: left;
    }
  }
}

.pmu-p-b-p-t-opt-w {
  padding-top: 24px;
}

.pmu-p-b-p-t-opt-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  min-height: 24px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.pmu-p-b-p-t-opt-t-c {
  display: table-cell;
  vertical-align: middle;

  &:first-child {
    [dir=ltr] & {
      text-align: left;
    }
    [dir=rtl] & {
      text-align: right;
    }
  }
  &:last-child {
    [dir=ltr] & {
      text-align: right;
    }
    [dir=rtl] & {
      text-align: left;
    }
  }

}

.pmu-p-b-p-t-opt-t-c-t {
  display: block;
  vertical-align: top;
  font-size: 12px;
  color: c(text-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-p-b-p-t-opt-t-c-v {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
}

.pmu-p-b-p-t-opt-t-c-v-icon {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-size: 16px;
  color: c(text-1);
}

.pmu-p-b-p-t-opt-t-c-v-h {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: $brand-color-1-bg;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  line-height: 24px;
  text-align: center;
}

.pmu-popup-b-t-w {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(c(text-4), 0.5);
}

.pmu-b-c-p-t {
  font-weight: normal;
  font-size: 12px;
  text-align: center;

  &.t-1 {
    color: c(text-1);
    line-height: 14px;
  }
  &.t-2 {
    padding-top: 8px;
    color: c(text-3);
    line-height: 14px;
  }

  &.t-fail {
    padding-top: 8px;
    color: $status-color-fail-bg;
    line-height: 14px;
  }

  &.t-success {
    padding-top: 8px;
    color: $status-color-success-bg;
    line-height: 14px;
  }

  &.t-confirm {
    padding-top: 8px;
    color: $status-color-pending-bg;
    line-height: 16px;
  }
}

.pmu-popup-notification-content{
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  position: relative;

  [dir=ltr] & {
    padding-left: 40px;
  }
  [dir=rtl] & {
    padding-right: 40px;
  }
}

.pmu-popup-n-icon-holder{
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-popup-n-icon{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  font-size: 32px;

  &.bc-i-success{
    color: $status-color-success-bg;
  }
  &.bc-i-attention{
    color: $status-color-pending-bg;
  }
  &.bc-i-fail{
    color: $status-color-fail-bg;
  }
}

.pmu-popup-n-text{
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: c(text-1);
}

.pmu-b-c-p-t-ch {
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 16px;
  margin: 0 4px;
  border-radius: 2px;
  background: rgba(c(skin-13), 0.2);
  text-align: center;
}

.pmu-b-c-p-c-t {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  border: 2px solid transparent;
}

.pmu-b-c-p-c-t-r {
  display: table-row;
}

.pmu-b-c-p-c-t-c {
  display: table-cell;
  vertical-align: top;
  height: 36px;
  border: 2px solid transparent;
}

.pmu-b-c-p-c-t-c-t {
  display: block;
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  line-height: 36px;
}

.pmu-b-c-p-c-t-c-b-i {
  width: 100%;
  height: 100%;
  background: c(skin-7, 0.5);
  text-align: center;
  line-height: 36px;
}

.pmu-b-c-p-c-t-c-b-icon {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
}

.pmu-b-c-p-c-t-c-b-i-h {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: $brand-color-1-bg;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  text-align: center;
  line-height: 24px;
}

.pmu-b-c-p-c-t-c-b-i-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
}

.pmu-b-c-p-c-t-c-b-i-t-icon {
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  font-weight: normal;
  font-size: 16px;
}

.pmu-popup-b-s-t-w {
  padding: 20px 34px 0 34px;
}

.pmu-popup-b-s-t {
  font-weight: normal;
  color: c(text-1);
  text-align: center;

  &.t-1 {
    font-size: 22px;
  }
  &.t-2 {
    padding-top: 24px;
    font-size: 12px;
    line-height: 14px;
  }
}

// ---------- Popups End ----------

@media screen and (max-width: 979px) {
  .pmu-popup-h {
    height: 32px;

    [dir=ltr] & {
      padding: 0 36px 0 8px;
    }
    [dir=rtl] & {
      padding: 0 8px 0 36px;
    }

    > .icon {

    }
  }

  .pmu-popup-h-t {
    font-size: 11px;
    line-height: 32px;
  }
  .pmu-popup-i {
    min-height: 36px;
  }
  .pmu-popup-i-h-t {
    line-height: 36px;
  }
}

@media screen and (max-width: 767px) {
  .pmu-popup {
    &.confirmation-popup {
      width: 100%;
    }
  }
}