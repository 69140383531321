@import "skin";

//------ TimeSpan Component Start --------
.pmu-time-span-and-details-wrapper {

  &.border-wrapper{
    border-right: 10px solid transparent;
  }

  &.finished {

    .pmu-t-s-i:after {
      border-color: transparent transparent c(skin-8) transparent;
    }
    .pmu-ts-d-h-t {
      background: linear-gradient(to top, transparent, c(skin-8));
    }
  }
}

.pmu-time-span-wrapper {
  height: 86px;
}

.pmu-t-s-scr {
  height: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.pmu-t-s-scr-ct {
  min-width: 100%;
  height: 86px;
  position: absolute;
  top: 0;
  white-space: nowrap;

  [dit=ltr] & {
    left: 0;
  }
  [dit=rtl] & {
    right: 0;
  }
}

.pmu-t-s-i {
  display: inline-block;
  vertical-align: top;
  width: 70px;
  height: 86px;
  padding-bottom: 10px;
  position: relative;

  [dir=ltr] & {
    margin-right: 5px;
  }
  [dir=rtl] & {
    margin-left: 5px;
  }

  &:last-child {
    margin: 0;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 0 0;
    border-color: transparent transparent $brand-color-1-bg transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: border $transition-length;
  }

  &.active {
    &:after {
      border-width: 0 10px 7px 10px;
    }

    > .pmu-t-s-i-ct {
      background: $brand-color-1-bg;

      > .pmu-t-s-i-ct-title,
      > .pmu-t-s-i-ct-time {
        color: $brand-color-1-txt-hover-active;
      }
    }
  }
}

.pmu-t-s-i-ct {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: c(skin-8);
  transition: background $transition-length;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: $brand-color-1-bg;
  }
}

.pmu-t-s-i-ct-icon {
  display: inline-block;
  vertical-align: top;
  padding-top: 12px;
  height: 27px;
}

.pmu-t-s-i-ct-title {
  display: block;
  padding-top: 10px;
  font-weight: normal;
  font-size: 12px;
  color: $brand-color-1-txt;
  text-transform: capitalize;
  transition: color $transition-length;
  width: 66px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pmu-t-s-i-ct-time {
  display: block;
  padding-top: 8px;
  font-weight: normal;
  font-size: 10px;
  color: $brand-color-1-txt;
  line-height: 10px;
  text-transform: uppercase;
  transition: color $transition-length;
}

//-------- TimeSpan Component End ---------

//-------- TimeSpan-Details Component Start ---------
.pmu-ts-details-wrapper {
  border-radius: 5px;
  overflow: hidden;
}

.pmu-ts-d-ct {
  width: 100%;
  height: 100%;
  position: relative;
  animation: pmu-ts-d-ct $transition-length;
}

@keyframes pmu-ts-d-ct {
  0% {
    transform: translateY(-18%);
  }
  100% {
    transform: translateY(0%);
  }
}

.pmu-ts-d-h-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 54px;
  padding: 8px 16px;
  background: linear-gradient(to top, #181c29, $brand-color-1-bg);  // need to change hex color to variable
}

.pmu-ts-d-h-t-c {
  display: table-cell;
  vertical-align: top;

  &:first-child {
    [dir=ltr] & {
      text-align: left;
    }
    [dir=rtl] & {
      text-align: right;
    }
  }
  &:last-child {
    [dir=ltr] & {
      text-align: right;
    }
    [dir=rtl] & {
      text-align: left;
    }
  }
}

.pmu-ts-d-h-l-txt {
  &.t-1 {
    font-weight: normal;
    font-size: 12px;
    color: $brand-color-1-txt;
    letter-spacing: normal;
  }
  &.t-2 {
    display: block;
    padding-top: 4px;
    font-weight: normal;
    font-size: 10px;
    color: $brand-color-1-txt;
  }
}

.pmu-ts-d-h-r-i {
  display: inline-block;
  min-width: 110px;
  min-height: 26px;
  border: 1px solid transparent;
  outline: none;
  box-sizing: border-box;
  padding: 0 22px;
  background: rgba($brand-color-1-bg, 0.7);
  border-radius: 2px;
  font-family: inherit;
  font-size: 10px;
  color: $brand-color-1-txt;
  text-align: center;
  line-height: 12px;
  box-shadow: 0px 1px 3px -1px c(skin-16);
  transition-duration: $transition-length;
  transition-property: background, border-color;
  cursor: pointer;

  &:hover {
    background: $brand-color-1-bg;
  }
}

.pmu-ts-d-b {
  height: 88px;
}

.pmu-ts-d-b-scr {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.pmu-ts-d-b-scr-ct {
  min-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-ts-d-b-t {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: c(skin-13);
}

.pmu-ts-d-b-c {
  display: table-cell;
  vertical-align: top;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  padding: 0 8px;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 26px;
    background: rgba(c(skin-2), 0.5);
    position: absolute;
    top: 1px;

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.pmu-ts-d-b-c-i-t {
  display: flex;
  justify-content:center;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: c(text-3);
  text-align: center;
  text-transform: uppercase;

  > .pmu-ts-d-b-c-i-t-a{
    display: inline-flex;
  }

  &.lowercase {
    text-transform: lowercase;
  }
}

.pmu-ts-d-b-c-i-t-d {
  display: inline-block;
  vertical-align: top;
  height: 22px;
  margin-top: 2px;
  padding: 0 6px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  font-size: 12px;
  line-height: 24px;
  background: c(skin-6);
  color: c(text-3);
  -webkit-user-select: none;

  [dir=ltr] & {
    margin-left: 4px;
  }
  [dir=rtl] & {
    margin-right: 4px;
  }
}

.pmu-ts-d-b-c-i-b {
  display: block;
  padding-top: 8px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-3);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-ts-d-b-c-i-t-icon {
  display: inline-block;
  vertical-align: top;
  font-size: 24px
}

//-------- TimeSpan-Details Component End -----------

@media screen and (max-width: 979px) {
  .pmu-time-span-and-details-wrapper {
    padding: 0;

    &.border-wrapper {
      border-right: 0;
    }
  }
  .pmu-t-s-i {
    [dir=ltr] & {
      margin-right: 2px;
    }
    [dir=rtl] & {
      margin-left: 2px;
    }
    &:last-child {
      margin: 0;
    }
  }
  .pmu-ts-d-h-t {
    padding: 8px;
  }
  .pmu-ts-d-h-r-i {

  }

  .pmu-ts-details-wrapper {
  }

  .pmu-ts-d-b {
    height: 60px;
  }

  .pmu-ts-d-b-t {
    table-layout: initial;
  }
  .pmu-ts-d-b-c {
    min-width: 90px;
    border-top: 8px solid #0000;
    //border-bottom: 8px solid #0000;

    &:after {
      height: 100%;
    }
  }

  .pmu-ts-d-b-c-i-t {
    font-size: 14px;
    line-height: 16px;
  }

  .pmu-ts-d-b-c-i-t-icon {
    font-size: 16px;
  }

  .pmu-ts-d-b-c-i-b {
    font-size: 9px
  }

  .pmu-ts-d-b-c-i-t-d {
    height: 15px;
    padding: 0 2px;
    margin-top: 0;
    font-size: 10px;
    line-height: 15px;
  }
}
