/*-------------------------------- VARIABLES START --------------------------------*/
//Global Variables Start
$icons-root: '../../assets/icons/bet_construct_icon_set/fonts/';
$font-root:  '../../assets/fonts';
$version-control: '?v=' + random(777);
$white-skin: false !default;
$transition-length: 0.23s !default;
//Global Variables End

//Typography Start
$font-family-default: Roboto, Arial, Helvetica, sans-serif;
$font-family-partner: null !default;
$font-family: if($font-family-partner == null, $font-family-default, $font-family-partner);
//Typography End

//Base Colors Start
$skin-color: #171c2c !default;
$text-color: #ffffff !default;

$hero-color: #a61f67 !default;

$brand-color-1-bg:               #1a7051 !default;
$brand-color-1-txt:              #ffffff !default;
$brand-color-1-bg-hover-active:  null !default;
$brand-color-1-txt-hover-active: null !default;

$brand-color-2-bg:               #c29440 !default;
$brand-color-2-txt:              #ffffff !default;
$brand-color-2-bg-hover-active:  null !default;
$brand-color-2-txt-hover-active: null !default;
//Base Colors End

//Status Colors Start
$status-color-success-bg:   #008D47 !default;
$status-color-success-txt:  #ffffff !default;

$status-color-pending-bg:   #F4DA16 !default;
$status-color-pending-txt:  #ffffff !default;

$status-color-fail-bg:      #E9044A !default;
$status-color-fail-txt:     #ffffff !default;

$status-color-disabled-bg:  #dddddd !default;
$status-color-disabled-txt: rgba(#000000, 0.4) !default;//FOR PMU TEMP, !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//Status Colors End

//Common Colors Start
$close-remove-color: #E9044A !default;
//Common Colors End

//Odd Colors Start
$odd-is-up-color: #52a535 !default;
$odd-is-down-color: #e9044a !default;
//Odd Colors End

//PMU Start
$pmu-quick-bet-time-color-1: #49b31a !default;
$pmu-quick-bet-time-color-2: #e9044a !default;

$pmu-horse-is-not-in-race: #e9044a !default;

$pmu-special-icon-color-1: #F25C19 !default; //couple-gagnant, couple-gagnant-e, couple-place, couple-placed-e, couple-ordre, couple-ordre-e
$pmu-special-icon-color-2: #00ACCF !default; //simple-place, simple-placed-e, simple-gagnant, simple-gagnant-e
$pmu-special-icon-color-3: #EC008C !default; //multi, multi-e
$pmu-special-icon-color-4: #d2c100 !default; //trio, trio-e, trio-ordre
$pmu-special-icon-color-5: #297ECB !default; //quarte-plus, quarte-plus-e
$pmu-special-icon-color-6: #DB1C30 !default; //quante-plus, quante-plus-e
$pmu-special-icon-color-7: #19AF61 !default; //tierce, tierce-e
$pmu-special-icon-color-8: #DA2032 !default; //quinte-plus
$pmu-special-icon-color-9: #FFF500 !default; //pick-five
$pmu-special-icon-color-9-text-color: #D40227 !default; //pick-five text color
$pmu-special-icon-color-10: #FFF500 !default; //pick-five
//PMU End

//Scrollbar Colors Start
$scrollbar-bg: c(skin-1) !default;
$scrollbar-thumb-bg: c(skin-10) !default;
//Scrollbar Colors End
/*-------------------------------- VARIABLES END --------------------------------*/


@mixin scroll($scroll: scroll) {
  $border-radius: 10px;
  $width: 4px;
  $scroll-main-color: c(skin-8);
  $scroll-main-thumb-color: c(skin-5);

  overflow-y: $scroll;
  overflow-x: hidden;
  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar {
    width: $width;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}
@function set-color($color, $color-dark: #000000, $color-light: #ffffff) {
  @if (lightness($color) > 50) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}
@function set-lightness($color, $amount) {
  @if (lightness($color) > 50) {
    @return darken($color, $amount);
  } @else {
    @return lighten($color, $amount);
  }
}
@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  } @else {
    @return rgba(var(--#{$color}-rgb), $opacity);
  }
}
@function sc($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color}-sc);
  } @else {
    @return rgba(var(--#{$color}-sc-rgb), $opacity);
  }
}
@function set-light-skin($return, $color) {
  @if (lightness($color) > 45) {
    @return black;
  } @else  {
    @return $return;
  }
}
