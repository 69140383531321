@import "skin";
.pmu-tv-ch-b{
  max-width: 1064px;
  margin: 0 auto;
  padding: 20px 20px 40px;
  &.center-t-v{
    text-align: center;
  }
}
.v-player-wrapper-b{
  width: 100%;
  > div{
    width: 100% !important;
    height: auto !important;
  }
}
.tv-ch-title-r{
  width: 100%;
  margin: 5px 0;
}
.ch-title-t-v{
  font-size: 12px;
  line-height: 16px;
  color: c(text-10);
  font-weight: normal;
  margin: 2px 0;
  > span{
    color: c(text-1);
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    text-transform: uppercase;
  }
}
.pmu-login-t-bc{
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: c(text-8);
  transition: color 240ms;
  cursor: pointer;
  &:hover{
    color: c(text-1);
  }
}