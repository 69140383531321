@import "skin";

//--------- How To Bet Component Start ---------
.pmu-h-t-b {
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 10px;

  p{
    font-weight: normal;
    font-size: 12px;
    color: c(text-2);
    line-height: 16px;
  }

  strong{
    font-weight: bold;
    font-size: 16px;
    color: c(text-4);
    line-height: 28px;
  }
}
//--------- How To Bet Component End -----------