@import "skin";

//---------- Betslip Help Component Start -------------
.pmu-help-wrapper {

}

.pmu-h-b {
  margin-top: 2px;
  padding: 20px 10px;
  background: c(skin-10);
}

.pmu-h-b-t-1 {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: c(text-2);
  letter-spacing: initial;
}

.pmu-h-b-t-2 {
  padding-top: 8px;
  font-weight: normal;
  font-size: 10px;
  color: c(text-3);
  text-align: justify;
  line-height: 12px;

  [dir=ltr] & {
    padding-right: 4px;
  }
  [dir=rtl] & {
    padding-left: 4px;
  }

  &.show-more {
    max-height: initial;
  }
}

.pmu-h-b-action {
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
  border-bottom: 1px solid c(text-10);
  font-weight: normal;
  font-size: 10px;
  color: c(text-10);
  line-height: 12px;
  transition-property: color, border-bottom-color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover {
    color: c(text-1);
    border-color: c(text-1);
  }
}

//---------- Betslip Help Component End ---------------

@media screen and (max-width: 979px) {
  .pmu-help-wrapper {
    display: none;
  }
}