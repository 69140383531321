@import "skin";

//---------- Select Horse Component Start -------------
.pmu-h-l-i {
  min-height: 60px;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: background $transition-length;
  background: c(skin-10);
  margin-bottom: 5px;

  &:nth-child(even){
    background: c(skin-8);
  }

  &:last-child{
    margin-bottom: 0;
  }

  &.active {
    z-index: 1;
  }


}

.pmu-h-l-i-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 60px;
  cursor: pointer;

  &:hover{
    background: c(skin-7);
  }
}

.pmu-h-l-i-t-c {
  display: table-cell;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 26px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: rgba(c(skin-8), 0.2);

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }
  }

  &.image {
    vertical-align: top;
    width: 42px;
  }

  &.buttons {
    vertical-align: middle;
    text-align: center;
    width: 38px;
    border-right: 1px solid c(text-5);
    &:last-child{
      border: none;
    }
  }

  &.text {
    vertical-align: middle;
    padding: 0 8px;
  }

  &.arrival-number {
    vertical-align: middle;
    width: 38px;
    text-align: center;
  }
}

.pmu-h-l-i-t-c-a-n {
  display: inline-block;
  vertical-align: top;
  width: 26px;
  height: 24px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  line-height: 24px;
  transition: background $transition-length;
  cursor: pointer;

  &:hover {
    background: $brand-color-1-bg;
  }
}

.pmu-h-l-i-t-c-i-w {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.pmu-h-l-i-t-c-i {
  display: inline-block;
  vertical-align: top;
  width: 100%;

  &[src=""] {
    display: none;
  }
}

.pmu-h-l-i-t-c-img {
  display: block;
  width: 100%;
  background: red !important; //Stugel, data chka
}

.pmu-h-l-i-t-c-t {
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.t-1 {
    font-size: 12px;
    color: c(text-2);
  }
  &.t-2 {
    padding: 2px 0;
    font-size: 10px;
    color: c(text-3);
  }
  &.t-3 {
    font-size: 10px;
    color: c(text-3);
  }
}

.pmu-h-l-i-t-c-t-status {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  text-transform: uppercase;
  color: $pmu-horse-is-not-in-race;

  [dir=ltr] & {
    padding-left: 4px;
  }
  [dir=rtl] & {
    padding-right: 4px;
  }
}

.pmu-h-l-i-t-c-btn {
  display: inline-block;
  vertical-align: top;
  width: 26px;
  height: 24px;
  border: none;
  padding: 0;
  outline: none;
  background: transparent;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  cursor: pointer;

  &.is-up {
    &:before {
      top: 0;
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      transition: opacity $transition-length;

      [dir=ltr] & {
        right: 0;
        border-width: 0 7px 7px 0;
        border-color: transparent $odd-is-up-color transparent transparent;
      }
      [dir=rtl] & {
        left: 0;
        border-width: 7px 7px 0 0;
        border-color: $odd-is-up-color transparent transparent transparent;
      }
    }
  }

  &.is-down {
    &:after {
      bottom: 0;
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      transition: opacity $transition-length;

      [dir=ltr] & {
        left: 0;
        border-width: 7px 0 0 7px;
        border-color: transparent transparent transparent $odd-is-down-color;
      }
      [dir=rtl] & {
        right: 0;
        border-width: 0 0 7px 7px;
        border-color: transparent transparent $odd-is-down-color transparent;
      }
    }
  }

  &:hover {
    > .pmu-h-l-i-t-c-icon {
      &.bc-i-arrow-bottom,
      &.bc-i-arrow-top,
      &.bc-i-checkbox-checked,
      &.bc-i-checkbox-unchecked {
        color: c(text-1);
      }

      &.bc-i-close-remove {
        color: $close-remove-color;
      }
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;

    > .pmu-h-l-i-t-c-icon {
      pointer-events: none;
    }
  }
}

.pmu-h-l-i-t-c-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: c(text-3);
  transition: color $transition-length;

  &.bc-i-arrow-right {
    [dir=rtl] & {
      transform: translate(-50%, -50%) rotateY(180deg);
    }
  }

  &.bc-i-close-remove {
    &:hover {
      color: $close-remove-color;
    }
  }

  &:hover {
    color: c(text-1);
  }
}

.pmu-h-l-i-o-t-h {
  padding: 0 10px;
}

.pmu-h-l-i-o-t-i {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.pmu-h-l-i-o-t-i-r {
  display: table-row;
  height: 34px;
  border-bottom: 1px solid c(skin-6);

  &:last-child {
    border-bottom: none;
  }
}

.pmu-h-l-i-o-t-i-c {
  display: table-cell;
  vertical-align: middle;

  &.txt {
    [dir=ltr] & {
      padding-left: 50px;
    }
    [dir=rtl] & {
      padding-right: 50px;
    }
  }

  &.num {
    width: 38px;
  }
}

.pmu-h-l-i-o-t-i-c-t {
  font-weight: normal;
  font-size: 12px;
  color: c(text-4);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-h-l-i-o-t-i-c-n {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  text-align: center;
}

.pmu-h-l-i-o-t {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: c(skin-10);
  border-radius: 5px;
  border: 1px solid c(text-6);

  [dir=ltr] & {
    padding: 0 46px 0 12px;
  }
  [dir=rtl] & {
    padding: 0 12px 0 46px;
  }
}

.pmu-h-l-i-o-t-c {
  height: 100%;

  &.list {
    white-space: nowrap;

  }
}

.pmu-h-l-i-o-t-c-t {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);

  line-height: 12px;
}

.pmu-h-l-i-o-t-c-bt {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  border: none;
  border-radius: 2px;
  outline: none;
  background: transparent;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  line-height: 22px;
  transition-property: color, background;
  transition-duration: $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    margin-right: 10px;
  }
  [dir=rtl] & {
    margin-left: 10px;
  }

  &:last-child {
    margin: 0;
  }

  &:hover,
  &.active {
    background: $brand-color-1-bg;
    color: c(text-1);
  }

  &.super-active {
    background: $brand-color-1-bg;
    color: c(text-1);
  }

  &.disabled {
    background: $status-color-disabled-bg;
    box-shadow: initial;
    color: $status-color-disabled-txt;
    pointer-events: none;
    -webkit-user-select: none;
  }
}

.pmu-h-l-i-o-t-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  font-size: 12px;
  position: absolute;
  top: 12px;
  color: c(text-2);
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    right: 12px;
  }
  [dir=rtl] & {
    left: 12px;
  }


  &:hover {
    color: $status-color-fail-bg;
  }
}


.pmu-h-l-i-o-h {
  margin-top: 12px;
  padding: 0 8px;
  background: c(skin-7);
}

.pmu-h-l-i-o-h-t {
  display: inline-block;
  font-weight: normal;
  font-size: 11px;
  color: c(text-4);
  text-transform: uppercase;
  line-height: 30px;
  transition: color $transition-length;
  position: relative;
  cursor: pointer;

  [dir=ltr] & {
    margin-right: 16px;
  }
  [dir=rtl] & {
    margin-left: 16px;
  }

  &:last-child {
    margin: 0;
  }

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 2px;
    background-color: $hero-color;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: width $transition-length;
  }

  &:hover,
  &.active {
    color: c(text-2);
  }

  &.active {
    &:after {
      width: 100%;
    }
  }
}

.pmu-h-l-i-o-b {
  padding: 2px 0 24px 0;
}

.pmu-h-l-i-o-b-l-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  background-color: c(skin-8);

  &:nth-child(odd) {
    background-color: c(skin-7); //rgba(c(skin-6), 0.2);  // not working rgba ??????
  }
}

.pmu-h-l-i-o-b-l-t-c {
  display: table-cell;
  vertical-align: middle;
  padding: 0 7px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-3);
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir=ltr] & {
    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
  [dir=rtl] & {
    &:first-child {
      text-align: right;
    }

    &:last-child {
      text-align: left;
    }
  }
}

.pmu-h-l-i-o-b-sb-ct-t {
  height: 30px;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 30px;
}

//----------- Select Horse Component End --------------

@media screen and (max-width: 720px) {
  .pmu-h-l-i {
    padding: 0 2px;
  }
  .pmu-h-l-i-o-t {
    display: block;
    padding: 16px 12px !important;
  }
  .pmu-h-l-i-o-t-c {
    display: block;
    height: auto;

    &.list {
      padding-top: 6px;
      white-space: initial;

      [dir=ltr] & {
        text-align: center;
      }
      [dir=rtl] & {
        text-align: center;
      }
    }
  }
  .pmu-h-l-i-o-t-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    top: 14px;
  }
  .pmu-h-l-i-o-t-c-bt {
    min-width: 30px;
    height: 30px;
    margin: 6px !important;
  }
}
