@import "~sass/settings";

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  src: local('Roboto Bold'),
  local('Roboto-Bold'),
  url('#{$font-root}/default/Roboto-Bold.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/default/Roboto-Bold.woff#{$version-control}') format('woff'),
  url('#{$font-root}/default/Roboto-Bold.ttf#{$version-control}') format('truetype'),
  url('#{$font-root}/default/Roboto-Bold.eot#{$version-control}') format('embedded-opentype'),
  url('#{$font-root}/default/Roboto-Bold.svg#{$version-control}#Roboto-Bold') format('svg');
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: local('Roboto'),
  local('Roboto-Regular'),
  url('#{$font-root}/default/Roboto-Regular.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/default/Roboto-Regular.woff#{$version-control}') format('woff'),
  url('#{$font-root}/default/Roboto-Regular.ttf#{$version-control}') format('truetype'),
  url('#{$font-root}/default/Roboto-Regular.eot#{$version-control}') format('embedded-opentype'),
  url('#{$font-root}/default/Roboto-Regular.svg#{$version-control}#Roboto-Regular') format('svg');
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: local('mardoto-regular'),
  url('#{$font-root}/arm/mardoto-regular-webfont.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/arm/mardoto-regular-webfont.woff#{$version-control}') format('woff'),
  url('#{$font-root}/arm/mardoto-regular-webfont.ttf#{$version-control}') format('truetype');
  unicode-range: U+0530-058F;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local('mardoto-bold'),
  url('#{$font-root}/arm/mardoto-bold-webfont.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/arm/mardoto-bold-webfont.woff#{$version-control}') format('woff'),
  url('#{$font-root}/arm/mardoto-bold-webfont.ttf#{$version-control}') format('truetype');
  unicode-range: U+0530-058F;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('IRAN_SemiBold'),
  url('#{$font-root}/farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('#{$font-root}/farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('#{$font-root}/farsi/IRAN_SemiBold.eot#{$version-control}');
  unicode-range: U+0600-06FF;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local('IRAN_SemiBold'),
  url('#{$font-root}/farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('#{$font-root}/farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('#{$font-root}/farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('#{$font-root}/farsi/IRAN_SemiBold.eot#{$version-control}');
  unicode-range: U+0600-06FF;
  font-display: fallback;
}