@import "skin";

@media only screen and (max-width: 979px) { // REMOVE LATER
  .pmu-racing-program-wrapper {
    display: none;
  }
  .pmu-m-racing-program-wrapper {
    display: block !important;
  }
}

// ---------- Mobile Racing Program Start ----------
.pmu-m-racing-program-wrapper {
  display: none;
}

.pmu-m-r-p-h {

}

.pmu-m-r-p-h-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 46px;
  background: rgba(c(skin-5), 0.6);
}

.pmu-m-r-p-h-t-c {
  display: table-cell;
  vertical-align: middle;

  &.s-1 {
    position: relative;
    padding-inline-end: 8px;
    padding-inline-start: 16px;

    &.show-arrow {
      padding-inline-start: 40px;
    }
  }

  &.s-2 {
    width: 32px;
    [dir=ltr] & {
      padding-right: 16px;
    }
    [dir=rtl] & {
      padding-left: 16px;
    }
  }
}

.pmu-m-r-p-h-txt {
  display: block;
  font-weight: normal;

  &.t-1 {
    font-size: 12px;
    color: c(text-2);
    line-height: 14px;
  }
  &.t-2 {
    margin-top: 2px;
    font-size: 10px;
    color: c(text-3);
    line-height: 12px;
  }
}

.pmu-m-r-p-h-t-arrow-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: c(text-2);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    left: 11px;
  }
  [dir=rtl] & {
    right: 11px;
    transform: rotateY(180deg);
  }
}

.pmu-m-r-p-h-t-r-c-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: c(text-2);
}

.pmu-m-r-p-h-r-t-w {
  margin-top: 2px;
}

.pmu-m-r-p-h-switcher {
  margin-top: 2px;
  height: 42px;
  position: relative;
  padding: 0 40px;
  background: c(skin-7);
}

.pmu-m-r-p-h-s-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: c(text-2);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &.left {
    [dir=ltr] & {
      left: 12px;
    }
    [dir=rtl] & {
      right: 12px;
      transform: rotateY(180deg);
    }
  }
  &.right {
    [dir=ltr] & {
      right: 12px;
    }
    [dir=rtl] & {
      left: 12px;
      transform: rotateY(180deg);
    }
  }
}

.pmu-m-r-p-h-s-txt {
  display: block;
  font-weight: normal;
  text-align: center;

  &.t-1 {
    padding-top: 7px;
    font-size: 12px;
    color: c(text-1);
    line-height: 14px;
  }
  &.t-2 {
    margin-top: 2px;
    font-size: 10px;
    color: c(text-2);
    line-height: 12px;
  }
}

.pmu-m-r-p-b {
  margin-top: 2px;
  padding: 8px 16px 10px 16px;
  background: c(skin-7);
}

.pmu-m-r-p-b-item {
  height: 90px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: c(skin-9);
  overflow: hidden;

  &.detailed {
    .pmu-m-r-p-b-i-s {
      &.s-1 {
        height: 30px;

        .pmu-m-r-p-b-i-s-c-t {
          border-bottom: none;
        }
      }
      &.s-2 {
        height: 30px;
      }
      &.s-3 {
        display: table;
        height: 30px;
        border-top: 1px solid c(skin-5);
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.pmu-m-r-p-b-i-s {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  height: 33%;

  &.s-1 {
    .pmu-m-r-p-b-i-s-c-t {
      border-bottom: 1px solid c(skin-5);
    }
  }
  &.s-2 {
  }
  &.s-3 {
    display: none;
  }
}

.pmu-m-r-p-b-i-s-c {
  display: table-cell;
  vertical-align: middle;

  &.label {
    width: 30px;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: c(text-1);

    &.l-1 {
      background: $brand-color-1-bg;
    }

    &.l-2 {
      background: c(skin-8);
    }
  }

  &.content {
    padding: 0 18px;
  }

  &.icon {
    width: 30px;
    font-size: 18px;
    color: c(text-3);
    text-align: center;
  }
}

.pmu-m-r-p-b-i-s-c-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.pmu-m-r-p-b-i-s-c-t-c {
  display: table-cell;
  vertical-align: middle;

  &.icon {
    width: 40px;
  }
}

.pmu-m-r-p-b-i-s-c-t-c-txt {
  display: block;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  [dir=ltr] & {
    padding-right: 16px;
  }
  [dir=rtl] & {
    padding-left: 16px;
  }

  &.t-1 {
    font-size: 12px;
    color: c(text-2);
    line-height: 14px;
  }
  &.t-2 {
    margin-top: 2px;
    font-size: 10px;
    color: c(text-2);
    line-height: 12px;
  }
  &.t-3 {
    font-size: 12px;
    color: c(text-3);
    line-height: 14px;
  }
  &.t-4 {
    font-size: 12px;
    color: c(text-3);
  }
}

.pmu-m-r-p-b-i-s-c-t-c-i-t {
  display: inline-block;
  font-size: 12px;
  color: c(text-3);
  line-height: 18px;
  position: relative;

  [dir=ltr] & {
    padding-left: 24px;
    margin-right: 16px;
  }
  [dir=rtl] & {
    padding-right: 24px;
    margin-left: 16px;
  }

  &:last-child {
    margin: 0;
  }
}

.pmu-m-r-p-b-i-s-c-t-c-icon {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    left: 0;
    margin-right: 8px;
  }
  [dir=rtl] & {
    right: 0;
    margin-left: 8px;
  }
}
// ---------- Mobile Racing Program Start ----------
