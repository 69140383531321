@import "skin";

.pmu-wrapper {
  width: 100%;
}

//-------- Program and Result Component Start -----------
.pmu-program-and-results-wrapper {

  &.width-wrapper{
    max-width: 1000px;
  }


}

//--------- Program and Result Component End ------------

//-------- PMU Special Icons Start -----------
.pmu-sp-logo {
  display: inline-block;
  position: relative;
  box-shadow: 0 0 0 1px #ffffff inset;
  color: #fff;
  overflow: hidden;

  [dir=ltr] & {
    float: left;
  }
  [dir=rtl] & {
    float: right;
  }

  //$pmu-special-icon-color-1
  &.bc-i-pmu-couple-gagnant,
  &.bc-i-pmu-couple-gagnant-e,
  &.bc-i-pmu-couple-place,
  &.bc-i-pmu-couple-placed-e,
  &.bc-i-pmu-couple-ordre,
  &.bc-i-pmu-couple-ordre-e {
    background: $pmu-special-icon-color-1;
  }

  //$pmu-special-icon-color-2
  &.bc-i-pmu-simple-place,
  &.bc-i-pmu-simple-placed-e,
  &.bc-i-pmu-simple-gagnant,
  &.bc-i-pmu-simple-gagnant-e {
    background: $pmu-special-icon-color-2;
  }

  //$pmu-special-icon-color-3
  &.bc-i-pmu-multi,
  &.bc-i-pmu-multi-e {
    background: $pmu-special-icon-color-3;
  }

  //$pmu-special-icon-color-4
  &.bc-i-pmu-trio,
  &.bc-i-pmu-trio-e,
  &.bc-i-pmu-trio-ordre {
    background: $pmu-special-icon-color-4;
  }

  //$pmu-special-icon-color-5
  &.bc-i-pmu-quarte-plus,
  &.bc-i-pmu-quarte-plus-e {
    background: $pmu-special-icon-color-5;
  }

  //$pmu-special-icon-color-6
  &.bc-i-pmu-quante-plus,
  &.bc-i-pmu-quante-plus-e,
  &.bc-i-pmu-quinte-plus {
    background: $pmu-special-icon-color-6;
  }

  //$pmu-special-icon-color-7
  &.bc-i-pmu-tierce,
  &.bc-i-pmu-tierce-e {
    background: $pmu-special-icon-color-7;
  }

  &.bc-i-pmu-quinte-plus {
    background: $pmu-special-icon-color-8;
  }

  &.bc-i-pmu-pick-five {
    background: $pmu-special-icon-color-9;
    color: $pmu-special-icon-color-9-text-color;
  }

  &.ext-1 {
    width: 38px;
    height: 15px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 15px;
  }

  &.ext-2 {
    width: 54px;
    height: 22px;
    border-radius: 22px;
    font-size: 18px;
    line-height: 22px;
  }

  &.ext-3 {
    width: 40px;
    height: 14px;
    border-radius: 14px;
    font-size: 14px;
    line-height: 14px;
  }

  &:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//--------- PMU Special Icons End ------------

//-------- Betting Component Start -----------
.pmu-betting-wrapper {
  margin-top: 10px;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 24px;
}

.pmu-b-l-s {
  max-width: 1000px;
  display: table-cell;

  [dir=ltr] & {
    border-right: 10px solid transparent;
  }
  [dir=rtl] & {
    border-left: 10px solid transparent;
  }
}

.pmu-b-r-s {
  display: table-cell;
  width: 314px;
  vertical-align: top;
}

//--------- Betting Component End ------------

//---------- Sub-content Header Component Start -------------
.pmu-sb-ct-header {
  height: 30px;
  padding: 0 10px;
  background: c(skin-8);
}

.pmu-sb-ct-l {
  display: inline-block;
  height: 100%;

  [dir=ltr] & {
    float: left;
  }
  [dir=rtl] & {
    float: right;
  }
}

.pmu-sb-ct-l-t {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  line-height: 30px;
}

.pmu-sb-ct-r {
  display: inline-block;
  height: 100%;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }

  &.ext-1 {
    > .pmu-sb-ct-r-t {
      position: relative;

      [dir=ltr] & {
        padding-right: 12px;
      }

      [dir=rtl] & {
        padding-left: 12px;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 26px;
        background: rgba(c(skin-1), 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;

        [dir=ltr] & {
          right: 0;
        }
        [dir=rtl] & {
          left: 0;
        }
      }
    }
  }
}

.pmu-sb-ct-r-t {
  display: inline-block;
  font-weight: normal;
  font-size: 10px;
  color: c(text-2);
  line-height: 30px;
  transition: color $transition-length;
  cursor: pointer;

  &:hover {
    color: c(text-4);
  }
}

.pmu-sb-ct-r-t-icon {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  font-size: 14px;

  [dir=ltr] & {
    margin-left: 4px;
  }
  [dir=rtl] & {
    margin-right: 4px;
  }
}

.pmu-sb-ct-r-t-a-b {
  display: inline-block;
  width: 38px;
  height: 30px;
  vertical-align: top;
  font-weight: normal;
  font-size: 10px;
  text-transform: capitalize;
  text-align: center;
  color: c(text-2);
  line-height: 30px;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 26px;
    background: rgba(c(skin-1), 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.pmu-sb-ct-h {
  height: 30px;
  padding: 0 10px;
  background: c(skin-6);
}

.pmu-sb-ct-h-l {
  [dir=ltr] & {
    float: left;
  }

  [dir=rtl] & {
    float: right;
  }
}

.pmu-sb-ct-h-t {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: rgba($color-white, 0.8);
  line-height: 30px;
}

.pmu-r-p-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background: c(skin-8);
  margin: 0 0 2px;
  padding: 0 10px;
}

.pmu-r-p-h-title {
  font-size: 12px;
  line-height: 30px;
  color: c(text-4);
  font-weight: bold;

  [dir=ltr] & {
    float: left;
  }

  [dir=rtl] & {
    float: right;
  }
}

.pmu-q-b-help {
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  line-height: 30px;
  color: c(text-1, 0.6);
  transition: color $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    float: right;
  }
  [dir=rtl] & {
    float: left;
  }

  &:hover {
    color: c(text-1);
  }
}

.pmu-q-b-h-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;

  [dir=ltr] & {
    margin-left: 8px;
  }
  [dir=rtl] & {
    margin-right: 8px;
  }
}

//----------- Sub-content Header Component End --------------

//----------- Sub-content Body Start --------------
.pmu-sb-ct-body {
  margin-top: 2px;
 // background: c(skin-10);
}

.pmu-sb-ct-b-s {
  border-bottom: 1px solid c(skin-6);

  &:last-child {
    border-bottom: none;
  }
}

//------------ Sub-content Body End ---------------

//-------- PMU NoData Start -----------
.pmu-no-data-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.pmu-n-d-w {
  display: block;
  width: 100%;
  opacity: 0.8;
}

.pmu-n-d-i {
  position: absolute;
  padding: 0 100px 66px;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-n-d-i-t {
  display: block;

  &.t-1 {
    padding-bottom: 22px;
    font-weight: bold;;
    font-size: 34px;
    text-transform: uppercase;
    color: c(text-1);
  }
  &.t-2 {
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 26px;
    color: c(text-4);
  }
  &.t-3 {
    padding-bottom: 35px;
    font-weight: normal;
    font-size: 14px;
    color: c(text-7);
  }
}

.pmu-n-d-i-t-a-b {
  display: inline-block;
  vertical-align: top;
  height: 26px;
  border-radius: 2px;
  padding: 0 8px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  line-height: 26px;
  cursor: pointer;

  &.ext-1 {
    min-width: 100px;
    background: $brand-color-1-bg;
    color: c(text-13);
    transition: background, color;
    transition-duration: $transition-length;

    [dir=ltr] & {
      margin-right: 24px;
    }
    [dir=rtl] & {
      margin-left: 24px;
    }

    &:hover {
      color: c(text-1);
      background: $brand-color-1-bg;
    }
  }
  &.ext-2 {
    color: c(text-9);
    transition: color $transition-length;

    &:hover {
      color: c(text-1);
    }
  }
}

//--------- PMU NoData Icons End ------------

//--------- PMU Message Box Start ------------
.pmu-message-box {
  position: fixed;
  top: 40px;
  z-index: 1;
  width: 220px;
  display: none;

  &.show {
    display: block;
  }

  [dir=ltr] & {
    right: 20px;
  }
  [dir=rtl] & {
    left: 20px;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent c(skin-5) transparent;

    [dir=ltr] & {
      right: 12px;
    }
    [dir=rtl] & {
      left: 12px;
    }
  }
}

.p-m-b-c {
  padding: 4px 8px;
  border-radius: 5px;
  background: c(skin-5);
  box-shadow: 0 0px 5px 0px rgba($color-black, 0.8);
  overflow: hidden;
}

.pmu-m-b-i {
  min-height: 24px;
  margin-bottom: 4px;
  position: relative;
  box-shadow: 0 1px 0 0 rgba(c(text-7), 0.1);

  [dir=ltr] & {
    padding-right: 32px;
  }

  [dir=rtl] & {
    padding-left: 32px;
  }

  &.error {
    > .pmu-m-b-i-t {
      color: $status-color-fail-bg;
    }
  }
  &.success {
    color: $status-color-success-bg;
  }

  &:last-child {
    margin-bottom: 0;
    box-shadow: none;
  }
}

.pmu-m-b-i-t {
  padding: 4px 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.pmu-m-b-i-btn {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 0;
  padding: 0;
  cursor: pointer;

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }

  &:hover {
    > .pmu-m-b-i-btn-icon {
      color: $close-remove-color;
    }
  }
}

.pmu-m-b-i-btn-icon {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: c(text-1);
  transition: color $transition-length;
}

//---------- PMU Message Box End -------------

//---------- Banner Start -------------
.pmu-banner {

}

.pmu-b-l {
  display: block;
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover{
    > .pmu-b-l-t-w{
      opacity: 1;
    }
  }
}

.pmu-b-l-i {
  display: block;
  width: 100%;
}

.pmu-b-l-t-w{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(c(skin-1), 0.5);
  opacity: 0;
  transition: opacity $transition-length;
}

.pmu-b-l-t {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  padding: 0 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 16px;
  color: c(text-4);
}

//---------- Banner End ---------------

@media screen and (max-width: 979px) {
  .pmu-program-and-results-wrapper {
    padding: 0 2px;
  }
  .pmu-betting-wrapper {
    display: block;
    margin-top: 0;
    padding-bottom: 0;
  }
  .pmu-b-l-s,
  .pmu-b-r-s {
    display: block;
    width: 100%;
  }

  .pmu-b-l-s {
    margin-bottom: 10px;
    [dir=ltr] & {
      border: none;
    }
    [dir=rtl] & {
      border: none;
    }
  }

  .pmu-n-d-i {
    padding: 20px;

    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }

  .pmu-n-d-i-t {
    &.t-1 {
      font-size: 20px;
      padding-bottom: 8px;
    }
    &.t-2 {
      font-size: 16px;
    }
    &.t-3 {
      font-size: 11px;
      padding-bottom: 14px;
    }
  }
}
