@import "skin";



:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
}

@import "./layout";
@import "./common";
@import "./popups";
@import "./racing_program";
@import "./mobile_racing_program";
@import "./quick_bet";
@import "./race_types";
@import "./time_span_and_details";
@import "./select_bet";
@import "./select_horse";
@import "./daily_races";
@import "./race_report";
@import "./betslip_help";
@import "./betslip";
@import "./my_bets";
@import "./how_to_bet";
@import "./tv_channel";
