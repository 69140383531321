@import "skin";

//--------- Race Component Start ---------
.pmu-p-r-race-types {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 82px;
  margin-bottom: 7px;
  border-right: 10px solid transparent;
}

.pmu-p-r-r-t-i {
  display: table-cell;
  vertical-align: top;
  height: 100%;

  [dir=ltr] & {
    border-right: 2px solid transparent;
  }
  [dir=rtl] & {
    border-left: 2px solid transparent;
  }

  &:last-child {
    border: none;
  }
}

.pmu-p-r-r-t-i-c {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 0 12px;
  background: c(skin-8);
  position: relative;
  overflow: hidden;
  transition: background $transition-length;
  cursor: pointer;
  contain: strict;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: width $transition-length;
    background: $brand-color-1-bg;
  }

  &:hover,
  &.active{
    background: c(skin-10);
  }

  &.active {
    &:after {
      width: 100%;
    }

    > .pmu-p-r-r-t-i-c-t {
      color: c(text-1);
    }
  }
}

.pmu-p-r-r-t-i-c-t {
  display: block;
  height: 50%;
  font-weight: normal;
  font-size: 14px;
  color: c(text-2);
  text-align: center;
  text-transform: capitalize;
  line-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: 1px solid c(skin-4);
  transition: color $transition-length;
}

.pmu-p-r-r-t-i-c-c {
  display: block;
  height: 50%;
  font-weight: normal;
  font-size: 14px;
  color: c(text-3);
  text-align: center;
  line-height: 40px;
}

.pmu-p-r-r-t-i-c-c-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;

  [dir=ltr] & {
    margin-right: 8px;
  }
  [dir=rtl] & {
    margin-left: 8px;
  }
}
//--------- Race Component End -----------

@media screen and (max-width: 979px) {
  .pmu-p-r-race-types {
    display: block;
    height: initial;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .pmu-p-r-r-t-i {
    display: inline-block;
    min-width: 86px;
    height: 60px;
  }
  .pmu-p-r-r-t-i-c {
    padding: 0 8px;
  }
  .pmu-p-r-r-t-i-c-t {
    font-size: 10px;
    line-height: 30px;
  }
  .pmu-p-r-r-t-i-c-c {
    font-size: 10px;
    line-height: 30px;
  }
}