@import "skin";

.pmu-global-wrapper {
  display: grid;
  max-width:1310px;
  width: 100%;
  min-height: 100%;
  grid-gap: 0;
  grid-template-rows: auto 10fr auto;
  grid-template-columns: auto;
  grid-template-areas: "header" "content" "footer";
}

.pmu-h {
  grid-area: header;
  width: 100%;
  height: 36px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 68;
  margin: 0 0 7px;
  text-align: center;
  background: c(skin-7);

  @include side(0);
}

.pmu-h-i {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 20px;
  font-size: 12px;
  line-height: 36px;
  text-transform: uppercase;
  color: c(text-3);
  text-decoration: none;
  transition: color 240ms;
  cursor: pointer;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 3px;
    background: $hero-color;
    max-width: 0;
    transition: max-width 240ms;
  }

  &:first-child {
    margin-left: 10px;
  }

  &:last-child {
    margin-right: 10px;
  }

  &:hover {
    color: c(text-3);
  }

  &.active {
    color: c(text-1);
    pointer-events: none;

    &:before {
      max-width: 100%;
    }
  }
}

.pmu-b {
  display:flex;
  grid-area: content;
  width: 100%;
  height: 100%;
  position: relative;
}

.pmu-f { //If needed !?
  grid-area: footer;
  width: 100%;
  overflow: hidden;
}

.popup-g-w {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999000;
  background: rgba($color-black, 0.4%);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: $scrollbar-thumb-bg $scrollbar-bg;
  scrollbar-width: thin;

  [dir=ltr] & {
    left: 0;
  }

  [dir=rtl] & {
    right: 0;
  }

  > .popup-c {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;

    > .popup-m {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      > .popup-i {
        display: inline-table;
        vertical-align: top;
        max-width: 100%;
        margin: 0 auto;
        text-align: initial;

        [dir=ltr] & {
          box-shadow: 2px 3px 8px 0 rgba($color-black, 0.5);
        }

        [dir=rtl] & {
          box-shadow: -2px 3px 8px 0 rgba($color-black, 0.5);
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .pmu-global-wrapper {
    display: -ms-grid;
    -ms-grid-auto-flow: column;
    -ms-grid-template-columns: auto auto auto;
    -ms-grid-template-rows: auto 10fr auto;
    -ms-grid-columns: 6fr;
    -ms-grid-rows: auto 10fr auto;
  }
  .pmu-h {
    -ms-grid-row: 1;
  }
  .pmu-b {
    -ms-grid-row: 2;
  }
  .pmu-f {
    -ms-grid-row: 3;
  }
}

@media screen and (max-width: 979px) {

  .pmu-b {
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ::selection {
    background-color: rgba(#000, 0.2);
  }

  body {
    -webkit-tap-highlight-color: rgba(#000, 0.3);
  }

  .pmu-global-wrapper {
    display: block;
    min-width: 320px;
  }

  .pmu-h {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .pmu-h-i {
    margin: 0 10px;
  }
}