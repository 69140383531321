@import "skin";

//----------- Daily Race Component Start --------------
.pmu-daily-races-wrapper {
}

.pmu-d-r-h {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  position: relative;
  background: c(skin-7);
}

.pmu-d-r-h-icon {
  display: inline-block;
  width: 24px;
  height: 30px;
  position: absolute;
  font-size: 16px;
  text-align: center;
  color: c(text-2);
  transition: color $transition-length;
  cursor: pointer;

  &.left {
    [dir=ltr] & {
      left: 0px;
    }
    [dir=rtl] & {
      right: 0px;
      transform: rotateY(180deg);
    }
  }

  &.right {
    [dir=ltr] & {
      right: 0px;
    }
    [dir=rtl] & {
      left: 0px;
      transform: rotateY(-180deg);
    }
  }

  &:before {
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
  }

  &:hover {
    color: c(text-1);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.pmu-d-r-h-t {
  font-weight: normal;
  font-size: 10px;
  color: c(text-4);
  text-align: center;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pmu-d-r-h-t-d {
  display: inline-block;
  vertical-align: top;
  color: c(text-1);
  text-transform: capitalize;

  [dir=ltr] & {
    margin-right: 4px;
  }
  [dir=rtl] & {
    margin-left: 4px;
  }
}

.pmu-d-r-f {
  margin-top: 2px;
}

.pmu-d-r-f {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  height: 48px;
}

.pmu-d-r-f-c {
  display: table-cell;
  vertical-align: top;
  height: 100%;

  [dir=ltr] & {
    border-right: 2px solid transparent;
  }
  [dir=rtl] & {
    border-left: 2px solid transparent;
  }

  &:last-child {
    border: none;
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
    -webkit-user-select: none;
  }
}

.pmu-d-r-f-c-ct {
  width: 100%;
  height: 100%;
  position: relative;
  background: c(skin-7);
  transition: background $transition-length;
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 2px;
    background: $brand-color-1-bg;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: width $transition-length;
  }

  &:hover,
  &.active {
    background: c(skin-9);
  }

  &.active {
    &:after {
      width: 100%;
    }

    > .pmu-d-r-f-c-ct-t {

      &.t-1{
        color: c(text-2);
      }
      &.t-2{
        color: c(text-3);
      }
    }
  }
}

.pmu-d-r-f-c-ct-t {
  display: block;
  font-weight: normal;
  color: c(text-2);
  text-align: center;
  transition: color $transition-length;

  &.t-1 {
    padding-top: 12px;
    font-size: 12px;
  }
  &.t-2 {
    padding-top: 4px;
    font-size: 10px;
  }
}

.pmu-d-r-b {
  margin-top: 2px;

  &.upcoming {
    > .pmu-d-r-b-i {
      > .pmu-d-r-b-i-c.main {

      }
      > .pmu-d-r-b-i-c.info {

      }
    }
  }

  &.completed {
    > .pmu-d-r-b-i {
      > .pmu-d-r-b-i-c.main {
        width: 60%;
      }
    }

    .pmu-d-r-b-i-c-i-w {
      &.ext-1 {
        > .pmu-d-r-b-i-c-t-n {
          background: c(skin-12);
        }
      }
      &.ext-2 {
        .pmu-d-r-b-i-c-t-n {
          background: c(skin-7);
        }
      }
    }
  }
}

.pmu-d-r-b-empty {
  display: table;
  width: 100%;
  height: 100%;
  background: c(skin-9);
  margin-bottom: 2px;
}

.pmu-d-r-b-empty-c {
  display: table-cell;
  vertical-align: middle;
  padding: 80px 55px;
  text-align: center;
}



.pmu-d-r-b-e-t {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: c(text-5);
  border-top: 2px solid c(text-5);
  padding-top: 10px;
}

.pmu-d-r-b-i {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  margin-bottom: 2px;
  background: c(skin-7);
  transition: background $transition-length;
  overflow: hidden;
  cursor: pointer;

  &:nth-child(even){
    background: c(skin-9);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: c(skin-6);
  }
}

.pmu-d-r-b-i-c {
  display: table-cell;
  vertical-align: top;

  &.main {
    width: 50%;
  }

  &.info {
    position: relative;

    &:last-child {
      .pmu-d-r-b-i-c-i-t-w:after {
        content: none;
      }
    }
  }
}

.pmu-d-r-b-i-c-i-w {
  height: 50%;
  position: relative;

  [dir=ltr] & {
    padding: 0 12px 0 32px;
  }
  [dir=rtl] & {
    padding: 0 32px 0 12px;
  }

  &.ext-1 {
    > .pmu-d-r-b-i-c-t-n {
      background: $brand-color-1-bg;
      color: $brand-color-1-txt;
    }
    > .pmu-d-r-b-i-c-t {
      font-size: 12px;
      color: c(text-2);
      font-weight: bold;
    }
  }

  &.ext-2 {
    > .pmu-d-r-b-i-c-t-n {
      background: c(skin-10);
      color: c(text-1);
    }
    > .pmu-d-r-b-i-c-t {
      font-size: 10px;
      color: c(text-3);
      font-weight:bold ;
    }
  }
}

.pmu-d-r-b-i-c-t {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  position: relative;
  font-weight: normal;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.has-special-icon {
    [dir=ltr] & {
      padding-right: 42px;
      height: 100%;
    }
    [dir=rtl] & {
      padding-left: 42px;
    }
  }
}

.pmu-d-r-b-i-c-t-n {
  display: inline-block;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    right: 0;
  }
}

.pmu-d-r-b-i-c-t-n-price {
  display: inline-block;
  vertical-align: top;
  position: relative;

  [dir=ltr] & {
    margin-left: 8px;
    padding-left: 8px;
  }
  [dir=rtl] & {
    margin-right: 8px;
    padding-right: 8px;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    height: 10px;
    width: 1px;
    margin: auto 0;
    background: c(skin-2);
    position: absolute;
    top: 0;
    bottom: 0;

    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }
}

.pmu-d-r-b-i-c-t-n-icon {
  display: inline-block;
  vertical-align: top;
  width: 38px;
  height: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-d-r-b-i-c-i-t-w {
  margin: 7px 0;
  padding: 0 6px;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 16px;
    background: rgba(c(skin-1), 0.2);
    position: absolute;
    top: 0;

    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }
}

.pmu-d-r-b-i-c-i-t {
  display: block;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.t-1 {
    font-weight: bold;
    font-size: 16px;
    text-transform: lowercase;
    color: c(text-3);
  }
  &.t-2 {
    padding-top: 8px;
    font-weight: normal;
    font-size: 10px;
    color: c(text-3);
  }
}

.pmu-d-r-b-i-c-i-t-t-z {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  padding: 0 6px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  font-size: 12px;
  line-height: 16px;
  background: c(skin-6);
  color: c(text-3);
  -webkit-user-select: none;

  [dir=ltr] & {
    margin-left: 4px;
  }
  [dir=rtl] & {
    margin-right: 4px;
  }
}

.pmu-d-r-b-i-c-i-t-icon {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
}

//----------- Daily Race Component End --------------

@media screen and (max-width: 979px) {
  .pmu-daily-races-wrapper {
    margin-top: 5px;
  }

  .pmu-d-r-b {
    &.upcoming {
      .pmu-d-r-b-i-c.info:nth-child(2) {
        width: 70px;
      }
    }

    &.completed {
      .pmu-d-r-b-i-c.info {
        width: 88px;
      }
    }
  }

  .pmu-d-r-b-i-c {
    &.main {
      width: 38%;
    }

    &.info {
      &:after {
        height: 100%;
      }
    }
  }

  .pmu-d-r-b-i-c-i-w {
    [dir=ltr] & {
      padding: 0 8px 0 32px;
    }
    [dir=rtl] & {
      padding: 0 32px 0 8px;
    }

    &.ext-1 {
      > .pmu-d-r-b-i-c-t {
        font-size: 11px;
      }
    }
    &.ext-2 {
      > .pmu-d-r-b-i-c-t {
        font-size: 10px;
      }
    }
  }

  .pmu-d-r-b-i-c-i-t-w {
    padding: 0 2px;
  }

  .pmu-d-r-b-i-c-i-t {
    &.t-1 {
      font-size: 12px;
    }
    &.t-2 {
      padding-top: 12px;
      font-size: 8px;
    }
  }

  .pmu-d-r-b-i-c-i-t-icon {
    font-size: 12px;
  }

  .pmu-d-r-b-i-c-i-t-t-z {
    height: 12px;
    padding: 0 2px;
    font-size: 10px;
    line-height: 12px;

    [dir=ltr] & {
      margin-left: 2px;
    }
    [dir=rtl] & {
      margin-right: 2px;
    }
  }

  .pmu-d-r-b-i-c-i-t-w:after {
    height: 12px;
  }
}
