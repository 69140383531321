@import "skin";

//--------- Select Bet Component Start ------------
.pmu-select-bet-wrapper {
  user-select: none;
  margin-bottom: 10px;
  > .pmu-sb-ct-body {
    padding: 0 10px;
    background: c(skin-10);
  }
}

.pmu-s-b-s-1 {
  height: 62px;
  padding: 20px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.pmu-s-b-s-1-i {
  display: inline-block;
  opacity: 0.6;
  transition: opacity $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    margin-right: 10px;
  }
  [dir=rtl] & {
    margin-left: 10px;
  }
  &:last-child {
    margin: 0;
  }

  &.active {
    opacity: 1;
  }
}

.pmu-s-b-s-2 {
}

.pmu-s-b-t {
  display: table;
  table-layout: fixed;
  max-width: 100%;
  padding: 22px 0;
}

.pmu-s-b-t-c {
  display: table-cell;
  vertical-align: top;
  min-width: 158px;

  [dir=ltr] & {
    border-right: 8px solid transparent;
  }
  [dir=rtl] & {
    border-left: 8px solid transparent;
  }
  &:last-child {
    border: none
  }
}

.pmu-s-b-t-c-bt {
  display: block;
  width: 100%;
  height: 24px;
  outline: none;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  border: none;
  border-radius: 2px;
  background: transparent;
  font-weight: normal;
  font-size: 11px;
  color: $brand-color-1-txt;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition-property: color, background;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover,
  &.active {
    background: $brand-color-1-bg;
  }
}

.pmu-s-b-s-3 {

}

.pmu-s-b-s-4 {
  padding-bottom: 16px;
}

.pmu-s-b-s-4-top {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-top: 16px;
}

.pmu-s-b-s-4-top-c {
  display: table-cell;
  vertical-align: top;

  &:last-child {
    width: 120px;
    vertical-align: middle;
  }
}

.pmu-s-b-s-4-top-c-txt {
  font-weight: normal;

  [dir=ltr] & {
    padding-right: 8px;
  }
  [dir=rtl] & {
    padding-left: 8px;
  }

  &.t-1 {
    font-size: 12px;
    color: c(text-1);
    line-height: 16px;
  }
  &.t-2 {
    padding-top: 5px;
    font-size: 10px;
    color: c(text-3);
    line-height: 14px;
  }
}

.pmu-s-b-s-4-bot {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-top: 16px;
}

.pmu-s-b-s-4-bot-c {
  display: table-cell;
  vertical-align: middle;

  &:last-child {
    text-align: right;
  }
}

.pmu-s-b-s-4-bot-c-t {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: c(text-1);
  line-height: 16px;
}

.pmu-s-b-s-4-bot-l {
  display: inline-block;
  list-style-type: none;
}

.pmu-s-b-s-4-bot-l-i {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: c(skin-7);
  font-weight: normal;
  font-size: 12px;
  color: c(text-2);
  text-align: center;
  line-height: 24px;
  transition-property: color, background;
  transition-duration: $transition-length;
  cursor: pointer;

  [dir=ltr] & {
    margin-right: 10px;
  }
  [dir=rtl] & {
    margin-left: 10px;
  }
  &:last-child {
    margin: 0;
  }

  &:hover,
  &.active {
    background: $brand-color-1-bg;
    color: $brand-color-1-txt;
  }
}

//---------- Select Bet Component End -------------

@media screen and (max-width: 720px) {
  .pmu-s-b-t.pmu-s-b-s-1,
  .pmu-s-b-t.pmu-s-b-s-2 {
    display: block;

    > .pmu-s-b-t-c {
      display: block;

      margin-bottom: 12px;

      [dir=ltr] & {
        border: none;
      }
      [dir=rtl] & {
        border: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pmu-s-b-t.pmu-s-b-s-3 {
    width: 100%;
    box-shadow: 0 0 0 1px #1a7051 inset;
    padding: 0;
    margin: 22px 0;
    border-radius: 2px;
    overflow: hidden;

    > .pmu-s-b-t-c {
      border: none;

      > .pmu-s-b-t-c-bt {
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  .pmu-s-b-t-c {
    min-width: initial;
  }

  .pmu-s-b-s-4-bot {
    padding-top: 0;
  }

  .pmu-s-b-s-4-bot-c {
    &:first-child {
      padding-top: 16px;
      display: table-caption;
    }

    &:last-child {
      padding-top: 10px;
      text-align: center;
    }
  }
  .pmu-s-b-s-4-bot-l-i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    [dir=ltr] & {
      margin: 6px;
    }
    [dir=rtl] & {
      margin: 6px;
    }
  }

  .pmu-s-b-t-c-bt {
    height: 30px;
  }
}
