@import "skin";

//------ Paris Open Component Start -------
.pmu-paris-open-wrapper {
  min-height: 100%;
  width: 100%;
}

.pmu-p-o-c {
  max-width: 1310px;
  margin: 0 auto;
}

.pmu-p-o-h {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 32px 10px 10px;
  background: c(skin-10);
}

.pmu-p-o-h-c {
  display: table-cell;
  vertical-align: top;
  max-width: 200px;

  [dir=ltr] & {
    border-right: 20px solid transparent;
  }
  [dir=rtl] & {
    border-left: 20px solid transparent;
  }

  &:last-child {
    border: none;
    max-width: 460px;
  }
}

.pmu-p-o-h-c-l {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;
  color: c(text-3);
  line-height: 14px;
  text-transform: capitalize;
}

.pmu-p-o-h-c-i-w {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 32px;
  margin-top: 5px;
}

.pmu-p-o-h-c-i-c {
  display: table-cell;
  vertical-align: top;
  height: 100%;

  [dir=ltr] & {
    border-right: 20px solid transparent;
  }
  [dir=rtl] & {
    border-left: 20px solid transparent;
  }

  &:last-child {
    border: none;
  }
}

.pmu-p-o-h-c-i-c-i {
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 1px rgba($color-white, 0.2) inset;
  > div {
    width: 100%;
    color: rgba(255, 255, 255, 0.87);
    &:before, &:after {
      content: none;
    }
    > label {
      display: none
    }
    > div {
      margin: 0;
      font-size: 11px;
      padding-left: 8px;
      height: 32px;
      color: rgba(255, 255, 255, 0.87);
      &:before, &:after {
        content: none;
      }
      svg {
        color: #999;
      }
      button {
        color: rgba(white, 0.54);
      }
      > div {

      }
    }
  }
}

.date-time-picker-modal {
  > div {
    > div {
      > div {
        &:first-child {
          > div:first-child {
            background: c(hero);
            color: white;
          }
          > div {
            &:last-child {
              filter: saturate(0);
            }
          }
        }
        &:last-child {
          button {
            color: c(hero);
          }
        }
      }
    }
  }
}

.pmu-p-o-h-c-i-c-i-btn {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 2px;
  font-family: inherit;
  font-size: 12px;
  color: $brand-color-1-txt;
  text-transform: uppercase;
  background: $brand-color-1-bg;
  transition-property: background, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover {
    background: $brand-color-1-bg-hover-active;
    color: $brand-color-1-txt-hover-active;
  }
}

.pmu-p-o-b {
  margin-top: 2px;
  -webkit-overflow-scrolling: touch;
}

.pmu-p-o-b-i {
  margin-bottom: 2px;
  padding: 0 10px;
  transition: background $transition-length;

  &.head {
    height: 30px;

    > .pmu-p-o-b-i-t {
      height: 100%;

      > .pmu-p-o-b-i-t-c {
        vertical-align: middle;
      }
    }
  }

  &.item {
    min-height: 46px;

    > .pmu-p-o-b-i-t {
      padding: 10px 0;

      > .pmu-p-o-b-i-t-c {
        vertical-align: top;
      }
    }

    &:hover,
    &.active {
      background: c(skin-6);
    }
  }

  &:nth-child(odd) {
    background: c(skin-8);
  }
  &:nth-child(even) {
    background: c(skin-7);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.pmu-p-o-b-i-t {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.pmu-p-o-b-i-t-c {
  display: table-cell;

  [dir=ltr] & {
    padding-right: 10px;
  }
  [dir=rtl] & {
    padding-left: 10px;
  }

  &.collapse-icon {
    width: 18px;
  }

  &:last-child {
    padding: 0;
  }
}

.pmu-p-o-b-i-t-c-h-t {
  display: block;
  font-weight: normal;
  font-size: 10px;
  color: c(text-4);
  line-height: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pmu-p-o-b-i-t-c-i {
  &.has-icon {
    position: relative;

    [dir=ltr] & {
      padding-right: 44px;
    }
    [dir=rtl] & {
      padding-left: 44px;
    }
  }
}

.pmu-p-o-b-i-t-c-i-t {
  display: block;
  font-weight: normal;

  &.t-1 {
    font-size: 12px;
    line-height: 14px;
    color: c(text-7);
  }
  &.t-2 {
    font-size: 10px;
    line-height: 12px;
    color: c(text-10);
    text-transform: capitalize;
  }
}

.pmu-p-o-b-i-t-c-icon {
  display: inline-block;
  width: 38px;
  height: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-p-o-b-i-t-c-c-i-w {
  display: inline-block;
  vertical-align: top;
  width: 18px;
  height: 18px;
}

.pmu-p-o-b-i-t-c-c-icon {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  color: c(text-4);
  transition-property: transform, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover {
    color: $hero-color;
  }

  &.active {
    transform: rotateX(180deg);
  }
}

.pmu-p-o-b-i-b-w {
  border-top: 1px solid rgba($color-white, 0.1);
  padding: 10px 0 14px 0;
}

.pmu-p-o-b-i-b {
  position: relative;

  [dir=ltr] & {
    padding-right: 180px;
  }
  [dir=rtl] & {
    padding-left: 180px;
  }
}

.pmu-p-o-b-i-b-t {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.pmu-p-o-b-i-b-t-r {
  display: table-row;
}

.pmu-p-o-b-i-b-t-r-c {
  display: table-cell;
  vertical-align: top;
}

.pmu-p-o-b-i-b-t-r-c,
.pmu-p-o-b-i-t-c {
  &:nth-child(1) {
    width: 164px;
  }
  &:nth-child(2) {
    width: 200px;
  }
}

.pmu-p-o-b-i-b-t-r-c-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 12px;

  [dir=ltr] & {
    padding-right: 8px;
  }

  [dir=rtl] & {
    padding-left: 8px;
  }

  &.t-1 {
    color: c(text-8);
  }
  &.t-2 {
    font-size: 10px;
    color: c(text-7);
  }
  &.t-3 {
    color: c(text-12);
  }
  &.t-4 {
    color: c(text-2);
  }
}

.pmu-p-o-b-i-b-a {
  width: 180px;
  height: 28px;
  position: absolute;
  bottom: 0;

  [dir=ltr] & {
    right: 0;
    text-align: right;
  }
  [dir=rtl] & {
    left: 0;
    text-align: left;
  }
}

.pmu-p-o-b-i-b-a-btn {
  display: inline-block;
  vertical-align: top;
  min-width: 120px;
  height: 100%;
  padding: 0 8px;
  border: none;
  outline: none;
  background: transparent;
  box-shadow: 0 0 0 1px $brand-color-1-bg inset;
  border-radius: 3px;
  font-family: inherit;
  font-size: 12px;
  color: $brand-color-1-txt;
  text-transform: capitalize;
  transition-property: background, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:hover {
    background: $brand-color-1-bg-hover-active;
    color: $brand-color-1-txt-hover-active;
  }
}

//----------------------------------------------------------------

.pmu-m-p-o-c {
  display: none;
}

.pmu-m-p-o-c-h {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 16px 16px;
  background: c(skin-9);
}

.pmu-m-p-o-c-h-t {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.pmu-m-p-o-c-h-t-c {
  display: table-cell;
  vertical-align: top;

  [dir=ltr] & {
    border-right: 8px solid transparent;
  }
  [dir=rtl] & {
    border-left: 8px solid transparent;
  }

  &:last-child {
    border: none;
  }
}

.pmu-m-p-o-c-h-t-c-ctrl-h {
  display: block;
  width: 100%;
  position: relative;
}

.pmu-m-p-o-c-h-t-ctrl {
  display: block;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid c(text-7);
  border-radius: 0;
  outline: none;
  background-color: transparent;
  appearance: none;
  font-family: inherit;
  font-weight: normal;
  font-size: 14px;
  color: c(text-4);
  transition-property: color, border;
  transition-duration: $transition-length;

  [dir=ltr] & {
    padding: 8px 32px 8px 8px;
  }

  [dir=rtl] & {
    padding: 8px 8px 8px 32px;
  }

  &:focus {
    color: c(text-5);
    border-color: c(text-7);

    & + .pmu-m-p-o-c-h-t-icon {
      color: c(text-4);
      transform: rotate(180deg);
    }
  }

  &.select {
    background-color: c(skin-9);
    text-transform: capitalize;
  }

  &.input-dt {
    /* &::-webkit-inner-spin-button,
     &::-webkit-calendar-picker-indicator {
       display: none;
       -webkit-appearance: none;
     }*/
  }
}

.pmu-m-p-o-c-h-t-btn {
  display: block;
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  background-color: $brand-color-1-bg;
  font-family: inherit;
  font-weight: normal;
  font-size: 14px;
  color: $brand-color-1-txt;
  text-transform: uppercase;
  transition-property: background-color, color;
  transition-duration: $transition-length;
  cursor: pointer;

  &:active {
    background-color: $brand-color-1-bg-hover-active;
    color: $brand-color-1-txt-hover-active;
  }
}

.pmu-m-p-o-c-h-t-icon {
  display: inline-block;;
  vertical-align: top;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 18px;
  color: c(text-4);
  line-height: 24px;
  transition: transform $transition-length ease;
  pointer-events: none;

  [dir=ltr] & {
    right: 4px;
  }
  [dir=rtl] & {
    left: 4px;
  }
}

.pmu-m-p-o-c-b {
  padding: 8px 16px;
}

.pmu-m-p-o-c-b-i {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  background: c(skin-5);

  &:last-child {
    margin-bottom: 0;
  }
}

.pmu-m-p-o-c-b-i-t {
  height: 60px;
  position: relative;
  border-bottom: 1px solid c(skin-2);

  [dir=ltr] & {
    padding-left: 30px;
  }
  [dir=rtl] & {
    padding-left: 30px;
  }
}

.pmu-m-p-o-c-b-i-t-m-w {
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    left: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-m-p-o-c-b-i-t-m {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 30px;
  font-weight: normal;
  font-size: 10px;
  color: c(text-7);
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;

  &.t-1 {
    background: c(skin-4);
  }
  &.t-2 {
    background: c(skin-2);
  }
}

.pmu-m-p-o-c-b-i-txt-w {
  padding: 8px 18px;
}

.pmu-m-p-o-c-b-i-txt {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.t-1 {
    position: relative;
    font-weight: bold;
    font-size: 12px;
    color: c(text-4);
    line-height: 16px;

    [dir=ltr] & {
      padding-right: 46px;
    }
    [dir=rtl] & {
      padding-left: 46px;
    }
  }
  &.t-2,
  &.t-3 {
    font-weight: normal;
    font-size: 10px;
    color: c(text-8);
    line-height: 14px;
  }
}

.pmu-m-p-o-c-b-i-txt-icon {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  top: 0;

  [dir=ltr] & {
    right: 0;
  }
  [dir=rtl] & {
    left: 0;
  }
}

.pmu-m-p-o-c-b-i-w {
  padding: 0 10px;
}

.pmu-m-p-o-c-b-i-m {

}

.pmu-m-p-o-c-b-i-m-t {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 12px 0;
}

.pmu-m-p-o-c-b-i-m-t-c {
  display: table-cell;
  vertical-align: middle;
  padding: 0 4px;
}

.pmu-m-p-o-c-b-i-m-t-c-t {
  display: block;
  text-align: center;
  color: c(text-10);

  &.t-1 {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
  &.t-2 {
    margin-top: 2px;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-transform: capitalize;
  }

  &.c-1 {
    text-transform: uppercase;
  }
  &.c-2 {
    text-transform: capitalize;
  }
}

.pmu-m-p-o-c-b-i-b {
  padding: 12px 0;
  border-top: 1px solid c(skin-2);
}

.pmu-m-p-o-c-b-i-b-t {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.pmu-m-p-o-c-b-i-b-t-r {
  display: table-row;
  border-bottom: 4px solid transparent;

  &:last-child {
    border-bottom: 0;
  }
}

.pmu-m-p-o-c-b-i-b-t-r-c {
  display: table-cell;
  vertical-align: top;

  &.ext-1 {
    width: 30%;
  }
  &.ext-2 {

  }
}

.pmu-m-p-o-c-b-i-b-t-r-c-t {
  display: block;
  font-weight: normal;
  font-size: 10px;

  &.t-1 {
    color: c(text-8);
  }
  &.t-2 {
    color: c(text-4);
  }
}

.pmu-m-p-o-c-r-w {
  margin-top: 16px;
}

.pmu-m-p-o-c-r-t-w {

}

.pmu-m-p-o-c-r-t {
  font-weight: normal;
  font-size: 10px;
  color: c(text-10);
}

.pmu-m-p-o-c-r-d-w {
  margin-top: 12px;
}

.pmu-m-p-o-c-r-d {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.pmu-m-p-o-c-r-d-c {
  display: table-cell;
  vertical-align: top;

  &:nth-child(1) {
    width: 30%;
  }
  &:nth-child(2) {
  }
}

.pmu-m-p-o-c-r-d-c-t {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 10px;
  color: c(text-1);
}

.pmu-m-p-o-c-r-a-w {
  margin-top: 12px;
}

.pmu-m-p-o-c-r-a {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 10px;
  color: $status-color-fail-bg;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 979px) {
  .pmu-p-o-h,
  .pmu-p-o-b {
    display: none;
  }

  .pmu-m-p-o-c {
    display: block;
  }

}

//------ Paris Open Component End ---------